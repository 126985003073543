import React, { useEffect, useState } from 'react';
import { NavigationPaths } from 'common/constants';
import { getSurveyInformationById } from 'services/surveyService';
import { useLocation, useNavigate } from 'react-router-dom';

import moment from 'moment';

import SuccessTick from 'assets/images/success-tick.svg';

const SurveysSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [surveyInformation, setSurveyInformation] = useState(null);

  useEffect(() => {
    getSurveyDetails();
  }, []);

  const getSurveyDetails = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
      };

      const surveyInformationResponse = await getSurveyInformationById(
        dataPayload
      );

      if (surveyInformationResponse) {
        if (Object.keys(surveyInformationResponse).length !== 0) {
          setSurveyInformation(surveyInformationResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const getRenderingDate = () => {
    let renderingDate = 'N/A';

    if (!!moment && surveyInformation) {
      renderingDate = moment(surveyInformation?.createdAt).format(
        'Do MMM, YYYY'
      );
    }

    return renderingDate;
  };

  const getRenderingTime = () => {
    let renderingTime = 'N/A';

    if (!!moment && surveyInformation) {
      renderingTime = moment(surveyInformation?.createdAt).format('hh:mm A');
    }

    return renderingTime;
  };

  const handleGoToDashboard = async () => {
    navigate(NavigationPaths.APPCORE, { replace: true });
  };

  return (
    <div className='surveys surveys-success'>
      <div className='surveys-content'>
        <div className='surveys-success-header'>
          <h2 className='surveys-success-title'>Submitted Successfully!</h2>
          <p className='surveys-success-description'>
            Thank you for submitting your responses.
          </p>
        </div>

        <div className='surveys-success-illustration'>
          <img
            width='164px'
            height='164px'
            src={SuccessTick}
            alt='Successfully Submitted'
            className='surveys-success-icon'
          />
        </div>

        <div className='surveys-success-meta'>
          {surveyInformation && (
            <div className='tabular-info tabular-info-multicolumn'>
              <div className='tabular-info-column'>
                {surveyInformation?.udiseId && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>UDISE Number</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.udiseId}
                    </span>
                  </div>
                )}

                {surveyInformation?.schoolName && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>School</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.schoolName}
                    </span>
                  </div>
                )}

                {surveyInformation?.subject && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Subject</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.subject?.subject}
                    </span>
                  </div>
                )}

                {surveyInformation?.createdAt && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Date</span>
                    <span className='tabular-info-description'>
                      {getRenderingDate()}
                    </span>
                  </div>
                )}

                {surveyInformation?.educatorName && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Surveyor Name</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.educatorName}
                    </span>
                  </div>
                )}
              </div>

              <div className='tabular-info-column'>
                {surveyInformation?.district && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>District</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.district}
                    </span>
                  </div>
                )}

                {surveyInformation?.className && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Class</span>
                    <span className='tabular-info-description'>
                      {`${surveyInformation?.className?.className}${
                        surveyInformation?.section ?? ''
                      }`}
                    </span>
                  </div>
                )}

                {surveyInformation?.teacher && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Teacher</span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.teacher?.teacherName}
                    </span>
                  </div>
                )}

                {surveyInformation?.createdAt && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Time</span>
                    <span className='tabular-info-description'>
                      {getRenderingTime()}
                    </span>
                  </div>
                )}

                {surveyInformation?.educatorDesignation && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>
                      Surveyor Designation
                    </span>
                    <span className='tabular-info-description'>
                      {surveyInformation?.educatorDesignation}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='surveys-content-options'>
          <button
            type='button'
            onClick={() => handleGoToDashboard()}
            className='secondary-cta secondary-cta-fullwidth secondary-cta-centered'
          >
            Go To Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveysSuccess;
