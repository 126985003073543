export const parseAddTeacherPayload = (
  formFields,
  temporarySelectedClasses,
  temporarySelectedSubjects
) => {
  let dataPayload = {};
  let classes = [];
  let subjects = [];

  if (formFields && Object.keys(formFields).length !== 0) {
    dataPayload.teacherName = formFields.addTeacherFullName;
  }

  if (
    temporarySelectedClasses &&
    Array.isArray(temporarySelectedClasses) &&
    temporarySelectedClasses.length !== 0
  ) {
    temporarySelectedClasses.forEach((currentSelectedClassObject) => {
      classes.push(currentSelectedClassObject?.value);
    });

    dataPayload.classes = classes;
  }

  if (
    temporarySelectedSubjects &&
    Array.isArray(temporarySelectedSubjects) &&
    temporarySelectedSubjects.length !== 0
  ) {
    temporarySelectedSubjects.forEach((currentSelectedSubjectObject) => {
      subjects.push(currentSelectedSubjectObject?.value);
    });

    dataPayload.subjects = subjects;
  }

  return dataPayload;
};
