import React, { useEffect, useState } from 'react';
import { getPastSurveysByUdise } from 'services/appCoreService';

import moment from 'moment';
import MenuHeader from 'components/MenuHeader';

const SurveysHistory = (props) => {
  const [pastSurveys, setPastSurveys] = useState([]);

  useEffect(() => {
    getPastSurveys();
  }, []);

  const getPastSurveys = async () => {
    try {
      const pastSurveyResponse = await getPastSurveysByUdise();

      if (pastSurveyResponse) {
        if (Array.isArray(pastSurveyResponse)) {
          if (pastSurveyResponse.length !== 0) {
            setPastSurveys(pastSurveyResponse);
          }
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Past Surveys', errorData);
    }
  };

  return (
    <div className='surveys'>
      <MenuHeader screenTitle='Past Surveys' />

      <div className='surveys-content'>
        {pastSurveys.map(
          (currentPastSurveyObject, currentPastSurveyObjectIndex) => {
            return (
              <div
                key={currentPastSurveyObjectIndex}
                className='tabular-info tabular-info-multicolumn tabular-info-withheader'
              >
                <div className='tabular-info-header'>
                  <span className='tabular-info-title'>
                    {currentPastSurveyObject?.surveyTitle}
                  </span>
                </div>

                <div className='tabular-info-content'>
                  <div className='tabular-info-column'>
                    {currentPastSurveyObject?.className && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>Class</span>
                        <span className='tabular-info-description'>
                          {currentPastSurveyObject?.className?.className}
                        </span>
                      </div>
                    )}

                    {currentPastSurveyObject?.teacher && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>
                          Teacher Observed
                        </span>
                        <span className='tabular-info-description'>
                          {currentPastSurveyObject?.teacher?.teacherName}
                        </span>
                      </div>
                    )}

                    {currentPastSurveyObject?.educatorName !== '' && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>
                          Surveyor Name
                        </span>
                        <span className='tabular-info-description'>
                          {currentPastSurveyObject?.educatorName}
                        </span>
                      </div>
                    )}

                    {currentPastSurveyObject?.educatorDesignation !== '' && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>
                          Surveyor Designation
                        </span>
                        <span className='tabular-info-description'>
                          {currentPastSurveyObject?.educatorDesignation}
                        </span>
                      </div>
                    )}

                    {/* REMOVED AS PER REQUEST FROM WISE OWL LEARNING */}
                    {/* <div className='tabular-info-line'>
                      <button type='button' className='link-cta'>
                        View Report
                      </button>
                    </div> */}
                  </div>

                  <div className='tabular-info-column'>
                    {currentPastSurveyObject?.subject && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>Subject</span>
                        <span className='tabular-info-description'>
                          {currentPastSurveyObject?.subject?.subject}
                        </span>
                      </div>
                    )}

                    {currentPastSurveyObject?.status && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>Status</span>
                        <span
                          className={
                            currentPastSurveyObject?.status === 'pending'
                              ? 'tabular-info-description status-pending'
                              : 'tabular-info-description status-completed'
                          }
                        >
                          {currentPastSurveyObject?.status === 'pending'
                            ? 'Incomplete'
                            : currentPastSurveyObject?.status === 'complete'
                            ? 'Completed'
                            : 'N/A'}
                        </span>
                      </div>
                    )}

                    {currentPastSurveyObject?.updatedAt && (
                      <div className='tabular-info-line'>
                        <span className='tabular-info-heading'>
                          Date & Time
                        </span>
                        <span className='tabular-info-description'>
                          {moment(currentPastSurveyObject?.updatedAt).format(
                            'Do MMM, YYYY hh:mm A'
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default SurveysHistory;
