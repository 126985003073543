import React from 'react';
import TextInput from 'components/TextInput';
import TitleHeader from 'components/TitleHeader';

const AuthLogin = (props) => {
  return (
    <div className='auth-login'>
      <TitleHeader
        screenTitle='Welcome!'
        screenDescription='Login to access the surveys.'
      />

      <div className='auth-login-content'>
        <TextInput
          maxLength='15'
          id='loginUdise'
          name='loginUdise'
          label='UDISE Number'
          placeholder='UDISE Number'
        />

        <TextInput
          maxLength='16'
          label='Password'
          id='loginPassword'
          name='loginPassword'
          placeholder='Password'
        />
      </div>

      <button
        type='button'
        className='primary-cta primary-cta-fullwidth primary-cta-centered'
      >
        Login
      </button>
    </div>
  );
};

export default AuthLogin;
