import React, { useState } from 'react';
import MenuOverlay from 'components/MenuOverlay';

import HamburgerIcon from 'assets/images/hamburger-icon.svg';

const MenuHeader = (props) => {
  const [menuDisplayed, setMenuDisplayed] = useState(false);

  const { screenTitle, screenDescription } = props;

  return (
    <>
      <div className='menu-header'>
        <div className='menu-header-left'>
          <h2 className='menu-header-heading'>{screenTitle}</h2>

          {screenDescription && screenDescription !== '' && (
            <p className='title-header-description'>{screenDescription}</p>
          )}
        </div>

        <div className='menu-header-right'>
          <button
            type='button'
            className='menu-hamburger'
            onClick={() => setMenuDisplayed(true)}
          >
            <img
              alt='Menu'
              src={HamburgerIcon}
              className='menu-hamburger-icon'
            />
          </button>
        </div>
      </div>

      {menuDisplayed && <MenuOverlay handleClose={setMenuDisplayed} />}
    </>
  );
};

MenuHeader.defaultProps = {
  screenDescription: '',
};

export default MenuHeader;
