import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  addSingleTeacher,
  getAllTeachersForSchool,
  getClassesAndSubjects,
  getSchoolProfile,
  removeSingleTeacher,
} from 'services/onboardingService';
import {
  dropDownFriendlyClasses,
  dropDownFriendlySubjects,
} from 'common/parsers';
import { parseAddTeacherPayload } from './parser';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import DropDown from 'components/DropDown';
import TextInput from 'components/TextInput';
import FeatureModal from 'components/FeatureModal';
import TeacherRecord from 'components/TeacherRecord';
import ProgressHeader from 'components/ProgressHeader';

import StateLogo from 'assets/images/state-logo.svg';
import CrossIconGey from 'assets/images/cross-icon-grey.svg';
import MenuHeader from 'components/MenuHeader';

const ProfileTeachersEdit = () => {
  const navigate = useNavigate();

  const [classData, setClassData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [schoolTeachers, setSchoolTeachers] = useState(null);
  const [schoolInformation, setSchoolInformation] = useState(null);
  const [addTeacherDialogVisible, setAddTeacherDialogVisible] = useState(false);
  const [formFields, setFormFields] = useState({
    addTeacherFullName: '',
  });
  const [addTeacherButtonDisabled, setAddTeacherButtonDisabled] =
    useState(true);
  const [temporarySelectedClasses, setTemporarySelectedClasses] = useState([]);
  const [temporarySelectedSubjects, setTemporarySelectedSubjects] = useState(
    []
  );

  useEffect(() => {
    getSchoolInformation();
    getClassesAndSubjectsForRendering();

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    let formValid = true;

    if (
      temporarySelectedClasses.length === 0 ||
      temporarySelectedSubjects.length === 0
    ) {
      formValid = false;
    }

    Object.keys(formFields).forEach((currentKey) => {
      if (
        formFields[currentKey] === '' ||
        formFields[currentKey] === null ||
        formFields[currentKey] === undefined
      ) {
        formValid = false;
      }
    });

    if (formValid) {
      setAddTeacherButtonDisabled(false);
    } else {
      setAddTeacherButtonDisabled(true);
    }
  }, [formFields, temporarySelectedClasses, temporarySelectedSubjects]);

  const getSchoolInformation = async () => {
    try {
      const schoolProfileResponse = await getSchoolProfile();

      if (schoolProfileResponse) {
        if (Object.keys(schoolProfileResponse).length !== 0) {
          setSchoolInformation(schoolProfileResponse);

          getTeachersInformation();
        }
      }
    } catch (errorData) {
      console.log('Error Fetching School Profile', errorData);
    }
  };

  const getClassesAndSubjectsForRendering = async () => {
    try {
      const getClassesResponse = await getClassesAndSubjects();

      if (getClassesResponse) {
        const convertedClassesForRendering = dropDownFriendlyClasses(
          getClassesResponse.classes
        );
        const convertedSubjectsForRendering = dropDownFriendlySubjects(
          getClassesResponse.subjects
        );

        setClassData(convertedClassesForRendering);
        setSubjectsData(convertedSubjectsForRendering);
      }
    } catch (errorData) {
      console.log('Error Fetching Class Details', errorData);
    }
  };

  const getTeachersInformation = async () => {
    try {
      const teachersForSchoolResponse = await getAllTeachersForSchool();

      if (teachersForSchoolResponse) {
        setSchoolTeachers(teachersForSchoolResponse);
      }
    } catch (errorData) {
      console.log('Error Fetching Teachers for School', errorData);
    }
  };

  const handleAddTeacherDialogToggle = (event) => {
    setAddTeacherDialogVisible((currentState) => !currentState);
  };

  const handleRemoveSelectedClass = (classIdentifier) => {
    let updatedTemporaryClasses = [...temporarySelectedClasses];

    updatedTemporaryClasses = updatedTemporaryClasses.filter(
      (currentArrayElement) => currentArrayElement.value !== classIdentifier
    );

    setTemporarySelectedClasses(updatedTemporaryClasses);
  };

  const handleFieldUpdate = (fieldIdentifier, fieldValue) => {
    // Special Handling for Multi-Selection Dropdown
    if (fieldIdentifier === 'addTeacherClassSelect') {
      let updatedTemporaryClasses = [...temporarySelectedClasses];

      const alreadySelectedClass = updatedTemporaryClasses.some(
        (currentTemporaryClassObject) => {
          if (currentTemporaryClassObject.value === fieldValue) {
            return true;
          }
        }
      );

      if (!alreadySelectedClass) {
        classData.forEach((currentClassObject) => {
          if (currentClassObject.value === fieldValue) {
            updatedTemporaryClasses.push(currentClassObject);
          }
        });

        setTemporarySelectedClasses(updatedTemporaryClasses);

        return;
      }
    }

    let updatedFormFields = Object.assign({}, formFields);

    updatedFormFields[`${fieldIdentifier}`] = fieldValue;

    setFormFields(updatedFormFields);
  };

  const handleToggleSelectedSubject = (selectedSubjectObject) => {
    let alreadySelectedSubject = false;

    if (Object.keys(selectedSubjectObject).length !== 0) {
      alreadySelectedSubject = temporarySelectedSubjects.some(
        (currentTemporarySubjectObject) => {
          if (
            currentTemporarySubjectObject?.value ===
            selectedSubjectObject?.value
          ) {
            return true;
          }
        }
      );

      let updatedTemporarySelectedSubjects = [...temporarySelectedSubjects];

      if (!alreadySelectedSubject) {
        updatedTemporarySelectedSubjects.push(selectedSubjectObject);
      } else {
        updatedTemporarySelectedSubjects =
          updatedTemporarySelectedSubjects.filter(
            (currentSubjectObject) =>
              currentSubjectObject?.value !== selectedSubjectObject?.value
          );
      }

      setTemporarySelectedSubjects(updatedTemporarySelectedSubjects);
    }
  };

  const getRenderingSubjectButtonClasses = (currentSubjectButtonValue) => {
    let renderingClasses = ['bool-cta'];
    let alreadySelectedSubject = false;

    alreadySelectedSubject = temporarySelectedSubjects.some(
      (currentTemporarySubjectObject) => {
        if (
          currentTemporarySubjectObject?.value === currentSubjectButtonValue
        ) {
          return true;
        }
      }
    );

    if (alreadySelectedSubject) {
      if (renderingClasses.includes('bool-cta-selected')) {
        renderingClasses = renderingClasses.filter(
          (currentRenderingClass) =>
            currentRenderingClass !== 'bool-cta-selected'
        );
      } else {
        renderingClasses.push('bool-cta-selected');
      }
    }

    return renderingClasses.join(' ');
  };

  const resetTeacherDialogStates = () => {
    setFormFields({
      addTeacherFullName: '',
    });
    setTemporarySelectedClasses([]);
    setTemporarySelectedSubjects([]);
  };

  const handleAddTeacherSubmit = async () => {
    let teacherFormValid = true;

    if (
      !formFields.addTeacherFullName ||
      formFields.addTeacherFullName === ''
    ) {
      teacherFormValid = false;
    } else if (
      temporarySelectedClasses.length === 0 ||
      temporarySelectedSubjects.length === 0
    ) {
      teacherFormValid = false;
    }

    if (teacherFormValid) {
      const dataPayload = parseAddTeacherPayload(
        formFields,
        temporarySelectedClasses,
        temporarySelectedSubjects
      );

      if (Object.keys(dataPayload).length === 3) {
        try {
          const addTeacherResponse = await addSingleTeacher(dataPayload);

          if (addTeacherResponse) {
            if (addTeacherResponse.hasOwnProperty('teacherId')) {
              resetTeacherDialogStates();
              handleAddTeacherDialogToggle();

              // Refresh Teachers Listings
              getTeachersInformation();
            }
          }
        } catch (errorData) {
          console.log('Error Adding Teacher', errorData);
        }
      }
    }
  };

  const handleRemoveTeacher = async (teacherIdentifier) => {
    try {
      const dataPayload = {
        data: { teacherId: teacherIdentifier },
      };

      const removeTeacherResponse = await removeSingleTeacher(dataPayload);

      if (removeTeacherResponse) {
        getTeachersInformation();
      }
    } catch (errorData) {
      console.log('Error Removing Teacher', errorData);
    }
  };

  const handleProceed = () => {
    navigate(`/${NavigationPaths.APPCORE}/${NavigationPaths.APPCOREPROFILE}`, {
      replace: true,
    });
  };

  return (
    <div className='profile auth-register auth-teacher'>
      <MenuHeader screenTitle='Manage Teachers' />

      <p className='profile-subtext'>
        Lorem ipsum dolor, Lorem ipsum dolor, Lorem ipsum dolor, Lorem ipsum
        dolor.
      </p>

      <div className='auth-register-meta'>
        {schoolInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {schoolInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.udiseId}
                  </span>
                </div>
              )}

              {schoolInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.schoolName}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {schoolInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.district}
                  </span>
                </div>
              )}

              {schoolInformation?.lowestClass &&
                schoolInformation?.highestClass &&
                classData &&
                classData.length !== 0 && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Class Range</span>
                    <span className='tabular-info-description'>
                      {`${schoolInformation?.lowestClass?.className} to ${schoolInformation?.highestClass?.className}`}
                    </span>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>

      <div className='auth-register-content'>
        <div className='auth-teacher-options'>
          <button
            className='link-cta link-cta-withplusicon'
            onClick={() => handleAddTeacherDialogToggle()}
          >
            Add Teacher
          </button>
        </div>

        {schoolTeachers &&
        Array.isArray(schoolTeachers) &&
        schoolTeachers.length !== 0 ? (
          <div className='auth-teacher-records'>
            {schoolTeachers.map(
              (currentTeacherObject, currentTeacherObjectIndex) => {
                return (
                  <TeacherRecord
                    readOnly={true}
                    classData={classData}
                    subjectsData={subjectsData}
                    key={currentTeacherObjectIndex}
                    recordData={currentTeacherObject}
                    removeAction={(removeActionIdentifier) =>
                      handleRemoveTeacher(removeActionIdentifier)
                    }
                    recordIndex={currentTeacherObjectIndex + 1}
                  />
                );
              }
            )}
          </div>
        ) : (
          <div className='auth-teacher-records auth-teacher-recordsempty'>
            No Teachers Added
          </div>
        )}

        <button
          type='button'
          disabled={
            schoolTeachers &&
            Array.isArray(schoolTeachers) &&
            schoolTeachers.length === 0
          }
          onClick={() => handleProceed()}
          className='primary-cta primary-cta-fullwidth primary-cta-centered'
        >
          Submit & Go Back
        </button>
      </div>

      {addTeacherDialogVisible && (
        <FeatureModal
          handleSubmit={handleAddTeacherSubmit}
          buttonDisabled={addTeacherButtonDisabled}
          handleClose={handleAddTeacherDialogToggle}
          headerChildren={
            <div className='add-teacher-header'>
              <h4 className='add-teacher-title'>Add Teacher</h4>
            </div>
          }
          contentChildren={
            <>
              <TextInput
                maxLength='50'
                label='Teacher Name'
                id='addTeacherFullName'
                name='addTeacherFullName'
                onChange={handleFieldUpdate}
                placeholder={`Teacher's Full Name`}
              />

              <div className='auth-teacher-subsection'>
                <h3 className='subsection-heading'>Teacher Category</h3>

                <DropDown
                  label='Select Class'
                  id='addTeacherClassSelect'
                  renderingOptions={classData}
                  name='addTeacherClassSelect'
                  onChange={handleFieldUpdate}
                  showConvertedSelection={true}
                  placeholder='Choose Classes Taught by Teacher'
                />

                {temporarySelectedClasses &&
                  Array.isArray(temporarySelectedClasses) &&
                  temporarySelectedClasses.length !== 0 && (
                    <div className='add-teacher-classes'>
                      {temporarySelectedClasses.map(
                        (
                          currentSelectedClassObject,
                          currentSelectedClassObjectIndex
                        ) => {
                          return (
                            <button
                              type='button'
                              onClick={() =>
                                handleRemoveSelectedClass(
                                  currentSelectedClassObject.value
                                )
                              }
                              className='selected-class'
                              key={currentSelectedClassObjectIndex}
                            >
                              {currentSelectedClassObject.label}
                              <img
                                src={CrossIconGey}
                                className='selected-class-icon'
                              />
                            </button>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              {subjectsData &&
                Array.isArray(subjectsData) &&
                subjectsData.length !== 0 && (
                  <div className='auth-teacher-subsection'>
                    <h3 className='subsection-heading'>Choose Subjects</h3>

                    <div className='auth-teacher-subjects'>
                      {subjectsData.map(
                        (currentSubjectObject, currentSubjectObjectIndex) => {
                          return (
                            <button
                              type='button'
                              key={currentSubjectObjectIndex}
                              className={getRenderingSubjectButtonClasses(
                                currentSubjectObject.value
                              )}
                              onClick={() =>
                                handleToggleSelectedSubject(
                                  currentSubjectObject
                                )
                              }
                            >
                              {currentSubjectObject.label}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </>
          }
        />
      )}
    </div>
  );
};

export default ProfileTeachersEdit;
