import { APIPaths } from 'common/constants';
import { apiInstance } from 'services/createService';

export const getMonitoringStatus = async () => {
  return await apiInstance.get(APIPaths.GETMONITORINGSTATUS);
};

export const getPastSurveysByUdise = async () => {
  return await apiInstance.get(APIPaths.GETPASTSURVEYS);
};
