import React from 'react';
import TextInput from 'components/TextInput';
import DateInput from 'components/DateInput';

import { QuestionTypes, SurveyTypes } from 'common/masters';

const AnswerCollector = (props) => {
  const handleAnswerUpdate = (fieldIdentifier, fieldValue) => {
    let updatedAnswerResponses = [...props.selectedAnswers];

    if (
      props.questionType === QuestionTypes.radioButton ||
      props.questionType === QuestionTypes.inputField ||
      props.questionType === QuestionTypes.dateField
    ) {
      updatedAnswerResponses[0] = fieldValue;
    } else {
      let alreadySelectedAnswer = false;

      alreadySelectedAnswer = updatedAnswerResponses.some(
        (currentAnswerResponse) => {
          if (currentAnswerResponse === fieldValue) {
            return true;
          }
        }
      );

      if (alreadySelectedAnswer) {
        updatedAnswerResponses = updatedAnswerResponses.filter(
          (currentAnswerResponse) => currentAnswerResponse !== fieldValue
        );
      } else {
        updatedAnswerResponses.push(fieldValue);
      }
    }

    props.handleAnswerUpdate(fieldIdentifier, updatedAnswerResponses);
  };

  const getRenderingAnswerPod = () => {
    let renderingPod = <></>;

    if (
      props.questionType === QuestionTypes.radioButton ||
      props.questionType === QuestionTypes.checkBoxButton
    ) {
      if (props.possibleAnswers && Array.isArray(props.possibleAnswers)) {
        if (props.possibleAnswers.length === 0) {
          return renderingPod;
        }
      } else {
        return renderingPod;
      }
    }

    switch (props.questionType) {
      case QuestionTypes.radioButton:
        renderingPod = (
          <div className='answer-collector-pod'>
            {props.possibleAnswers.map(
              (currentAnswerObject, currentAnswerObjectIndex) => {
                return (
                  <label
                    className='answer-radio'
                    key={currentAnswerObjectIndex}
                    htmlFor={`answerRadio${currentAnswerObjectIndex + 1}`}
                  >
                    <input
                      type='radio'
                      name='answerRadio'
                      checked={props.selectedAnswers.some(
                        (currentAnswer) =>
                          currentAnswer === currentAnswerObject.answerValue
                      )}
                      className='answer-radio-input'
                      onChange={(event) =>
                        handleAnswerUpdate('answerRadio', event.target.value)
                      }
                      value={currentAnswerObject.answerValue}
                      id={`answerRadio${currentAnswerObjectIndex + 1}`}
                    />
                    <span className='answer-radio-circle' />
                    <span className='answer-radio-text'>
                      {currentAnswerObject.answerLabel}
                    </span>
                  </label>
                );
              }
            )}
          </div>
        );
        break;

      case QuestionTypes.checkBoxButton:
        renderingPod = (
          <div className='answer-collector-pod'>
            {props.possibleAnswers.map(
              (currentAnswerObject, currentAnswerObjectIndex) => {
                return (
                  <label
                    className='answer-checkbox'
                    key={currentAnswerObjectIndex}
                    htmlFor={`answerCheckBox${currentAnswerObjectIndex + 1}`}
                  >
                    <input
                      type='checkbox'
                      name='answerCheckBox'
                      className='answer-checkbox-input'
                      checked={props.selectedAnswers.some(
                        (currentAnswer) =>
                          currentAnswer === currentAnswerObject.answerValue
                      )}
                      onChange={(event) =>
                        handleAnswerUpdate('answerCheckbox', event.target.value)
                      }
                      value={currentAnswerObject.answerValue}
                      id={`answerCheckBox${currentAnswerObjectIndex + 1}`}
                    />
                    <span className='answer-checkbox-square' />
                    <span className='answer-checkbox-text'>
                      {currentAnswerObject.answerLabel}
                    </span>
                  </label>
                );
              }
            )}
          </div>
        );
        break;

      case QuestionTypes.inputField:
        renderingPod = (
          <div className='answer-collector-pod'>
            <TextInput
              type='text'
              label='Answer'
              maxLength='100'
              bypassMode={true}
              id='answerTextField'
              name='answerTextField'
              onChange={handleAnswerUpdate}
              placeholder='Type Your Answer Here'
              value={props.selectedAnswers[0] ?? ''}
              numberMode={
                !!(
                  props.surveyType === SurveyTypes.readinessBaseline ||
                  props.surveyType === SurveyTypes.readinessEndline
                )
              }
            />
          </div>
        );
        break;

      case QuestionTypes.dateField:
        renderingPod = (
          <div className='answer-collector-pod'>
            <DateInput
              label='Answer'
              readOnly={true}
              id='answerDateField'
              name='answerDateField'
              placeholder='Choose a Date'
              onChange={handleAnswerUpdate}
              value={props.selectedAnswers[0] ?? ''}
            />
          </div>
        );
        break;

      default:
        renderingPod = <></>;
        break;
    }

    return renderingPod;
  };

  return <div className='answer-collector'>{getRenderingAnswerPod()}</div>;
};

export default AnswerCollector;
