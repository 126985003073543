import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  getSurveyInformation,
  getSurveyResponses,
  updateSurveyMetaThree,
} from 'services/surveyService';
import { NavigationPaths } from 'common/constants';
import { useLocation, useNavigate } from 'react-router-dom';

import MenuHeader from 'components/MenuHeader';
import QAReviewSegment from 'components/QAReviewSegment';

const SurveysReview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [surveyInformation, setSurveyInformation] = useState(null);
  const [surveyResponses, setSurveyResponses] = useState([]);

  useEffect(() => {
    getSurveyDetails();
    getSurveyContent();
  }, []);

  const getSurveyDetails = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyInformation(dataPayload);

      if (surveyInformationResponse) {
        if (Object.keys(surveyInformationResponse).length !== 0) {
          setSurveyInformation(surveyInformationResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const getSurveyContent = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyResponses(dataPayload);

      if (surveyInformationResponse) {
        if (surveyInformationResponse.hasOwnProperty('surveyResponses')) {
          setSurveyResponses(surveyInformationResponse.surveyResponses);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const handleConfirmAndSubmit = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const updateMetaOneResponse = await updateSurveyMetaThree(dataPayload);

      if (updateMetaOneResponse) {
        if (
          updateMetaOneResponse.successfullySubmitted &&
          updateMetaOneResponse.hasOwnProperty('message')
        ) {
          navigate(
            `/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYSUCCESS}`,
            {
              replace: true,
              state: location?.state,
            }
          );
        }
      }
    } catch (errorData) {
      console.log('Error Updating Survey Information', errorData);
    }
  };

  return (
    <div className='surveys surveys-performer'>
      <MenuHeader
        screenTitle='Survey'
        screenDescription={location?.state?.surveyTitle ?? `N/A`}
      />

      <p className='surveys-subtext'>
        Here are your responses to this survey. These will be submitted once you
        press Confirm & Submit below.
      </p>

      <div className='surveys-meta'>
        {surveyInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {surveyInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.udiseId}
                  </span>
                </div>
              )}

              {surveyInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.schoolName}
                  </span>
                </div>
              )}

              {surveyInformation?.subject && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Subject</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.subject?.subject}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {surveyInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.district}
                  </span>
                </div>
              )}

              {surveyInformation?.className && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Class</span>
                  <span className='tabular-info-description'>
                    {`${surveyInformation?.className?.className}${
                      surveyInformation?.section ?? ''
                    }`}
                  </span>
                </div>
              )}

              {surveyInformation?.teacher && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Teacher</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.teacher?.teacherName}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='surveys-content'>
        <div className='surveys-content-review'>
          {surveyResponses.map(
            (currentResponseObject, currentResponseObjectIndex) => {
              return (
                <QAReviewSegment
                  key={currentResponseObjectIndex}
                  segmentTitle={
                    currentResponseObject?.questionTitle !== ''
                      ? currentResponseObject?.questionTitle
                      : undefined
                  }
                  segmentDescription={
                    currentResponseObject?.questionDescription
                  }
                  segmentAnswer={currentResponseObject?.answer}
                />
              );
            }
          )}
        </div>

        <div className='surveys-content-options'>
          <button
            type='button'
            onClick={() => handleConfirmAndSubmit()}
            className='primary-cta primary-cta-fullwidth primary-cta-centered'
          >
            Confirm &amp; Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveysReview;
