import React from 'react';

import MeghalayaMizoramStateLogo from 'assets/images/mizoram-meghalaya-state-logo.svg';
import SikkimStateLogo from 'assets/images/sikkim-state-logo.svg';
import NagalandStateLogo from 'assets/images/nagaland-state-logo.svg';
import PlaceholderStateLogo from 'assets/images/state-logo-placeholder.svg';

const TitleHeader = (props) => {
  const { screenTitle, screenImageDisplayed, currentState, screenDescription } =
    props;

  const getRenderingStateLogo = () => {
    let renderingLogo = PlaceholderStateLogo;

    if (currentState) {
      if (
        currentState.toLowerCase() === 'meghalaya' ||
        currentState.toLowerCase() === 'mizoram'
      ) {
        renderingLogo = MeghalayaMizoramStateLogo;
      } else if (currentState.toLowerCase() === 'sikkim') {
        renderingLogo = SikkimStateLogo;
      } else if (currentState.toLowerCase() === 'nagaland') {
        renderingLogo = NagalandStateLogo;
      }
    }

    return renderingLogo;
  };

  return (
    <div className='title-header'>
      <div className='title-header-left'>
        <h2 className='title-header-heading'>{screenTitle}</h2>
        <p className='title-header-description'>{screenDescription}</p>
      </div>

      {screenImageDisplayed && (
        <div className='title-header-right'>
          <img
            width={'100%'}
            alt='State Logo'
            height={'100px'}
            src={getRenderingStateLogo()}
            className='title-header-image'
          />
        </div>
      )}
    </div>
  );
};

TitleHeader.defaultProps = {
  currentState: 'none',
  screenImageDisplayed: false,
};

export default TitleHeader;
