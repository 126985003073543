import { onlyNumbersRegex } from 'common/constants';
import React, { useState } from 'react';

const TextInput = (props) => {
  // Handle Pre-Assignment of Data from Here
  const [fieldValue, setFieldValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleFieldBoundaryClick = (event) => {
    const targetClassName = event?.target?.className;

    if (targetClassName && targetClassName.split(' ').includes('form-field')) {
      document.getElementById(props.id).focus();
    }
  };

  const getRenderingFieldStates = () => {
    let fieldClasses = ['form-field'];

    if (!!props.rightAction) {
      fieldClasses.push('form-field-actionable');
    }

    if (isFocused) {
      fieldClasses.push('form-field-focused');
    }

    if (fieldValue && fieldValue !== '' && !isFocused) {
      fieldClasses.push('form-field-dirty');
    }

    return fieldClasses.join(' ');
  };

  const handleFieldValueUpdate = (event) => {
    // Validate for Only Digits Input in NumberMode
    if (props.numberMode) {
      if (
        onlyNumbersRegex.test(event?.target?.value) === false &&
        event?.target?.value !== ''
      ) {
        return;
      }
    }

    if (!props.bypassMode) {
      setFieldValue(event?.target?.value);
    }

    // Call Handler Callback from Here
    props.onChange(props.id, event?.target?.value);
  };

  return (
    <div
      onClick={handleFieldBoundaryClick}
      className={getRenderingFieldStates()}
    >
      <label className='form-field-label'>{props.label}</label>
      <input
        id={props.id}
        type={props.type}
        name={props.name}
        maxLength={props.maxLength}
        className='form-field-input'
        placeholder={props.placeholder}
        onChange={handleFieldValueUpdate}
        value={props.bypassMode ? props.value : fieldValue}
        onBlur={() => setIsFocused((currentState) => !currentState)}
        onFocus={() => setIsFocused((currentState) => !currentState)}
      />
      {!!props.rightAction && <>{props.rightAction}</>}
    </div>
  );
};

TextInput.defaultProps = {
  type: 'text',
  onChange: () => {},
  bypassMode: false,
};

export default TextInput;
