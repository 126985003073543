export const NavigationPaths = {
  HOME: '/',
  ONBOARDING: 'auth',
  ONBOARDINGREGISTER: 'register',
  ONBOARDINGTEACHERS: 'teachers',
  ONBOARDINGLOGIN: 'login',
  APPCORE: 'app',
  APPCOREPROFILE: 'profile',
  APPCOREPROFILETEACHERS: 'teachers',
  SURVEY: 'survey',
  SURVEYBEGIN: 'begin',
  SURVEYPERFORMER: 'performer',
  SURVEYMETA: 'metadata',
  SURVEYPROOFING: 'proofing',
  SURVEYREVIEW: 'review',
  SURVEYSUCCESS: 'success',
  CATCHALL: '*',
};

export const APIPaths = {
  INITIALAUTH: '/neeve/auth',
  GETCLASSESANDSUBJECTS: '/teacher/getSubAndClasses',
  UPDATESCHOOLPROFILE: '/user/update-profile',
  GETSCHOOLPROFILE: '/school/profile',
  GETALLTEACHERSFORSCHOOL: '/teacher/getAll',
  ADDSINGLETEACHER: '/teacher/create',
  REMOVESINGLETEACHER: '/teacher/delete',
  FINALSUBMITONBOARDING: '/user/submitOnboard',
  GETMONITORINGSTATUS: '/survey/getMonitoringStatus',
  CREATESURVEY: '/survey/create',
  GETSURVEYINFORMATION: '/survey/getSurvey',
  GETSURVEYQUESTION: '/survey/getQuestions',
  SUBMITSURVEYANSWER: '/survey/postAnswer',
  UPDATEMETADATAONE: '/survey/updateMetaDataOne',
  GETMEDIA: '/survey/getMedia',
  UPLOADMEDIA: '/survey/uploadMedia',
  REMOVEMEDIA: '/survey/deleteMedia',
  UPDATEMETADATATWO: '/survey/updateMetaDataTwo',
  GETSURVEYRESPONSES: '/survey/getAnswers',
  UPDATEMETADATATHREE: '/survey/updateMetaDataThree',
  GETPASTSURVEYS: '/survey/pastSurvey',
  GETSURVEYBYID: '/survey/getSurveyDetailsById',
};

export const onlyNumbersRegex = new RegExp(/^[\d]+$/);
