import { NavigationPaths } from 'common/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  getClassesAndSubjects,
  updateSchoolProfile,
} from 'services/onboardingService';
import { dropDownFriendlyClasses } from 'common/parsers';

import DropDown from 'components/DropDown';
import TextInput from 'components/TextInput';
import TitleHeader from 'components/TitleHeader';
import ProgressHeader from 'components/ProgressHeader';

const AuthRegisterProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [classData, setClassData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [formFields, setFormFields] = useState({
    profileSchoolEnrollment: '',
    profileSchoolLowestClass: '',
    profileSchoolHighestClass: '',
  });

  useEffect(() => {
    getClassesForRendering();
  }, []);

  const getClassesForRendering = async () => {
    try {
      const getClassesResponse = await getClassesAndSubjects();

      if (getClassesResponse) {
        const convertedClassesForRendering = dropDownFriendlyClasses(
          getClassesResponse.classes
        );

        setClassData(convertedClassesForRendering);
      }
    } catch (errorData) {
      console.log('Error Fetching Class Details', errorData);
    }
  };

  useLayoutEffect(() => {
    let formValid = true;

    Object.keys(formFields).forEach((currentKey) => {
      if (
        formFields[currentKey] === '' ||
        formFields[currentKey] === null ||
        formFields[currentKey] === undefined
      ) {
        formValid = false;
      }

      if (currentKey === 'profileSchoolEnrollment') {
        if (formFields[currentKey] <= 0) {
          formValid = false;
        }
      }
    });

    if (formValid) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formFields]);

  const handleFieldUpdate = (fieldIdentifier, fieldValue) => {
    let updatedFormFields = Object.assign({}, formFields);

    updatedFormFields[`${fieldIdentifier}`] = fieldValue;

    setFormFields(updatedFormFields);
  };

  const handleProceed = async () => {
    let formValid = true;

    Object.keys(formFields).forEach((currentKey) => {
      if (
        formFields[currentKey] === '' ||
        formFields[currentKey] === null ||
        formFields[currentKey] === undefined
      ) {
        formValid = false;
      }
    });

    if (formValid) {
      try {
        const dataPayload = {
          schoolEnroll: formFields.profileSchoolEnrollment,
          lowestClass: formFields.profileSchoolLowestClass,
          highestClass: formFields.profileSchoolHighestClass,
        };

        const updateSchoolProfileResponse = await updateSchoolProfile(
          dataPayload
        );

        if (updateSchoolProfileResponse) {
          if (
            updateSchoolProfileResponse.hasOwnProperty('message') &&
            updateSchoolProfileResponse.hasOwnProperty('profileUpdated')
          ) {
            const transferStateObject = {
              ...location?.state,
            };

            navigate(NavigationPaths.ONBOARDINGTEACHERS, {
              replace: true,
              state: transferStateObject,
            });
          }
        }
      } catch (errorData) {
        console.log('Error Updating School Profile', errorData);
      }
    }
  };

  return (
    <div className='auth-register auth-profile'>
      <ProgressHeader disableBackButton={true} filledProgress={'48.5%'} />

      <TitleHeader
        screenTitle='Welcome!'
        screenImageDisplayed={true}
        currentState={location?.state?.State}
        screenDescription='Create School Profile'
      />

      <p className='auth-register-subtext'>
        Help us understand more about the School by filling up basic details.
      </p>

      <div className='auth-register-meta'>
        <div className='tabular-info tabular-info-multicolumn'>
          <div className='tabular-info-column'>
            {!!location.state.UDISE_code && (
              <div className='tabular-info-line'>
                <span className='tabular-info-heading'>UDISE Number</span>
                <span className='tabular-info-description'>
                  {location.state.UDISE_code}
                </span>
              </div>
            )}

            {!!location.state.school_name && (
              <div className='tabular-info-line'>
                <span className='tabular-info-heading'>School</span>
                <span className='tabular-info-description'>
                  {location.state.school_name}
                </span>
              </div>
            )}
          </div>

          <div className='tabular-info-column'>
            {!!location.state.district && (
              <div className='tabular-info-line'>
                <span className='tabular-info-heading'>District</span>
                <span className='tabular-info-description'>
                  {location.state.district}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='auth-register-content'>
        {/* REMOVED ON REQUEST FROM WISE OWL LEARNING */}
        {/* <DropDown
          label='Select District'
          id='profileSchoolDistrict'
          name='profileSchoolDistrict'
          placeholder='Select District'
        /> */}

        <TextInput
          type='number'
          maxLength='5'
          label='School Enrollment'
          id='profileSchoolEnrollment'
          onChange={handleFieldUpdate}
          name='profileSchoolEnrollment'
          placeholder='School Enrollment'
        />

        <div className='auth-profile-subsection'>
          <h3 className='subsection-heading'>Select School Category</h3>
          <p className='subsection-description'>
            Choose the lowest and the highest class in your school.
          </p>

          <DropDown
            label='Select Lowest Class'
            renderingOptions={classData}
            onChange={handleFieldUpdate}
            showConvertedSelection={true}
            id='profileSchoolLowestClass'
            name='profileSchoolLowestClass'
            placeholder='Choose Lowest Class'
          />

          <DropDown
            label='Select Highest Class'
            renderingOptions={classData}
            onChange={handleFieldUpdate}
            showConvertedSelection={true}
            id='profileSchoolHighestClass'
            name='profileSchoolHighestClass'
            placeholder='Choose Highest Class'
          />
        </div>

        <button
          type='button'
          disabled={buttonDisabled}
          onClick={() => handleProceed()}
          className='primary-cta primary-cta-fullwidth primary-cta-centered'
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default AuthRegisterProfile;
