export const parseTeachersForDropdown = (teachersArray) => {
  let renderingTeachers = [];

  if (
    teachersArray &&
    Array.isArray(teachersArray) &&
    teachersArray.length !== 0
  ) {
    teachersArray.forEach((currentTeacherObject) => {
      renderingTeachers.push({
        label: currentTeacherObject.teacherName,
        value: currentTeacherObject._id,
      });
    });
  }

  return renderingTeachers;
};
