import React, { useState } from 'react';

import moment from 'moment';
import Calendar from 'react-calendar';

import CalendarIcon from 'assets/images/calendar-icon-black.svg';

const DateInput = (props) => {
  // Handle Pre-Assignment of Data from Here
  const [fieldValue, setFieldValue] = useState('');
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerValue, setPickerValue] = useState(new Date());

  const handleFieldBoundaryClick = (event) => {
    const targetClassName = event?.target?.className;

    if (targetClassName && targetClassName.split(' ').includes('form-field')) {
      document.getElementById(props.id).focus();
      setPickerVisible((currentState) => !currentState);
    }
  };

  const getRenderingFieldStates = () => {
    let fieldClasses = ['form-field'];

    if (fieldValue && fieldValue !== '') {
      fieldClasses.push('form-field-dirty');
    }

    return fieldClasses.join(' ');
  };

  const handleFieldValueUpdate = (nextFieldValue) => {
    setPickerValue(nextFieldValue);

    if (!props.bypassMode) {
      setFieldValue(moment(nextFieldValue).format('D/M/YYYY'));
    }

    // Call Handler Callback from Here
    props.onChange(props.id, nextFieldValue);

    setTimeout(() => setPickerVisible(false), 500);
  };

  return (
    <>
      <div
        onClick={handleFieldBoundaryClick}
        className={getRenderingFieldStates()}
      >
        <label className='form-field-label'>{props.label}</label>
        <input
          type='text'
          id={props.id}
          name={props.name}
          readOnly={props.readOnly}
          className='form-field-input'
          placeholder={props.placeholder}
          onChange={handleFieldValueUpdate}
          value={props.bypassMode ? props.value : fieldValue}
          onClick={() => setPickerVisible((currentState) => !currentState)}
        />
        <button
          type='button'
          className='form-field-trigger'
          onClick={() => setPickerVisible((currentState) => !currentState)}
        >
          <img
            alt='Date Picker'
            src={CalendarIcon}
            className='form-field-dateicon'
          />
        </button>
      </div>

      {pickerVisible && (
        <Calendar onChange={handleFieldValueUpdate} value={pickerValue} />
      )}
    </>
  );
};

DateInput.defaultProps = {
  onChange: () => {},
  bypassMode: false,
};

export default DateInput;
