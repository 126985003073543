export const dropDownFriendlyClasses = (apiResponseArray) => {
  let responseArray = [];

  if (apiResponseArray && Array.isArray(apiResponseArray)) {
    apiResponseArray.forEach((currentResponseObject) => {
      responseArray.push({
        label: currentResponseObject?.className,
        value: currentResponseObject?._id,
      });
    });
  }

  return responseArray;
};

export const dropDownFriendlySubjects = (apiResponseArray) => {
  let responseArray = [];

  if (apiResponseArray && Array.isArray(apiResponseArray)) {
    apiResponseArray.forEach((currentResponseObject) => {
      responseArray.push({
        label: currentResponseObject?.subject,
        value: currentResponseObject?._id,
      });
    });
  }

  return responseArray;
};
