export const sectionMaster = [
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
  {
    label: 'C',
    value: 'C',
  },
  {
    label: 'D',
    value: 'D',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'G',
    value: 'G',
  },
  {
    label: 'H',
    value: 'H',
  },
  {
    label: 'Not Available',
    value: 'Not Available',
  },
];

export const QuestionTypes = {
  radioButton: 'MultipleChoiceRadio',
  checkBoxButton: 'MultipleChoiceCheckbox',
  inputField: 'InputText',
  dateField: 'DatePicker',
};

export const EducatorDesignations = [
  {
    label: 'Subject Teacher',
    value: 'Subject Teacher',
  },
  {
    label: 'HOI / Principal',
    value: 'HOI / Principal',
  },
  {
    label: 'Administration',
    value: 'Administration',
  },
  {
    label: 'Consultants',
    value: 'Consultants',
  },
];

export const SurveyTypes = {
  halfye: 'HALFYE',
  term1Exam: 'TERM1E',
  term2Exam: 'TERM2E',
  term3Exam: 'TERM3E',
  term4Exam: 'TERM4E',
  finalExam: 'FINALE',
  readinessEndline: 'RENDL',
  readinessBaseline: 'RBASE',
};

export const ExamPortalSurveys = [
  'RBASE',
  'RENDL',
  'TERM1E',
  'TERM2E',
  'TERM3E',
  'TERM4E',
  'HALFYE',
  'FINALE',
];

export const SurveyPortalSurveys = ['CLOBTI', 'CLOBTF', 'CLOBSI', 'CLOBSF'];
