import React from 'react';
import Navigation from 'navigation';
import ReactDOM from 'react-dom/client';

import 'react-calendar/dist/Calendar.css';
import 'styles/imports.scss';

const reactRootElement = ReactDOM.createRoot(
  document.getElementById('application-root')
);

reactRootElement.render(<Navigation />);
