import React from 'react';

const QuestionDescriber = (props) => {
  return (
    <div className='question-describer'>
      {props?.questionData?.questionTitle && (
        <h4 className='question-describer-title'>
          {props?.questionData?.questionTitle}
        </h4>
      )}

      {props?.questionData?.questionDescription && (
        <p className='question-describer-description'>
          {props?.questionData?.questionDescription}
        </p>
      )}
    </div>
  );
};

export default QuestionDescriber;
