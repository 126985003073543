import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  getSurveyInformation,
  getSurveyMedia,
  removeSurveyMedia,
  updateSurveyMetaTwo,
  uploadSurveyMedia,
} from 'services/surveyService';
import { NavigationPaths } from 'common/constants';
import { EducatorDesignations } from 'common/masters';
import { useLocation, useNavigate } from 'react-router-dom';

import DropDown from 'components/DropDown';
import TextInput from 'components/TextInput';
import MenuHeader from 'components/MenuHeader';
import UploadArea from 'components/UploadArea';

const SurveysMetaProofing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState({
    surveyEducatorName: '',
    surveyEducatorDesignation: '',
  });
  const [photoOne, setPhotoOne] = useState(null);
  const [photoTwo, setPhotoTwo] = useState(null);
  const [surveyInformation, setSurveyInformation] = useState(null);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

  useEffect(() => {
    getSurveyDetails();
    getAlreadyAttachedMedia();
  }, []);

  useLayoutEffect(() => {
    let formValid = true;

    Object.keys(formFields).forEach((currentKey) => {
      if (
        formFields[currentKey] === '' ||
        formFields[currentKey] === null ||
        formFields[currentKey] === undefined
      ) {
        formValid = false;
      }
    });

    if (formValid) {
      setContinueButtonDisabled(false);
    } else {
      setContinueButtonDisabled(true);
    }
  }, [formFields]);

  const getSurveyDetails = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyInformation(dataPayload);

      if (surveyInformationResponse) {
        if (Object.keys(surveyInformationResponse).length !== 0) {
          setSurveyInformation(surveyInformationResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const getAlreadyAttachedMedia = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyMedia(dataPayload);

      if (surveyInformationResponse) {
        if (surveyInformationResponse.hasOwnProperty('media')) {
          if (Array.isArray(surveyInformationResponse.media)) {
            if (surveyInformationResponse.media.length === 2) {
              setPhotoOne({
                fileUrl: surveyInformationResponse.media[0].fileUrl,
                fileIdentifier: surveyInformationResponse.media[0]._id,
              });
              setPhotoTwo({
                fileUrl: surveyInformationResponse.media[1].fileUrl,
                fileIdentifier: surveyInformationResponse.media[1]._id,
              });
            } else if (surveyInformationResponse.media.length === 1) {
              setPhotoOne({
                fileUrl: surveyInformationResponse.media[0].fileUrl,
                fileIdentifier: surveyInformationResponse.media[0]._id,
              });
            } else {
              setPhotoOne(null);
              setPhotoTwo(null);
            }
          }
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const handleFieldUpdate = (fieldIdentifier, fieldValue) => {
    let updatedFormFields = Object.assign({}, formFields);

    updatedFormFields[`${fieldIdentifier}`] = fieldValue;

    setFormFields(updatedFormFields);
  };

  const handleUploadPhoto = async (photoSequence, uploadedFile) => {
    try {
      const dataPayload = new FormData();
      dataPayload.append('file', uploadedFile, uploadedFile?.name);
      dataPayload.append('surveyId', location?.state?.surveyId);
      dataPayload.append('surveyType', location?.state?.surveyType);

      const uploadFileResponse = await uploadSurveyMedia(dataPayload);

      if (uploadFileResponse) {
        if (
          uploadFileResponse.hasOwnProperty('fileUrl') &&
          uploadFileResponse.hasOwnProperty('fileName')
        ) {
          if (photoSequence === 'photoOne') {
            setPhotoOne({
              fileUrl: uploadFileResponse.fileUrl,
              fileIdentifier: uploadFileResponse._id,
            });
          } else if (photoSequence === 'photoTwo') {
            setPhotoTwo({
              fileUrl: uploadFileResponse.fileUrl,
              fileIdentifier: uploadFileResponse._id,
            });
          }
        }
      }
    } catch (errorData) {
      console.log('Error Uploading Media', errorData);

      if (photoSequence === 'photoOne') {
        setPhotoOne(null);
      } else if (photoSequence === 'photoTwo') {
        setPhotoTwo(null);
      }
    }
  };

  const handleRemovePhoto = async (photoSequence, selectedPhotoIdentifier) => {
    try {
      const dataPayload = {
        data: {
          surveyId: location?.state?.surveyId,
          surveyType: location?.state?.surveyType,
          mediaId: selectedPhotoIdentifier,
        },
      };

      const removeMediaResponse = await removeSurveyMedia(dataPayload);

      if (removeMediaResponse) {
        if (removeMediaResponse.hasOwnProperty('successfullyDeleted')) {
          if (photoSequence === 'photoOne') {
            setPhotoOne(null);
          } else if (photoSequence === 'photoTwo') {
            setPhotoTwo(null);
          }
        }
      }
    } catch (errorData) {
      console.log('Error Removing Media', errorData);
    }
  };

  const handleContinueAndProceed = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
        educatorName: formFields.surveyEducatorName,
        educatorDesignation: formFields.surveyEducatorDesignation,
      };

      const updateMetaOneResponse = await updateSurveyMetaTwo(dataPayload);

      if (updateMetaOneResponse) {
        if (
          updateMetaOneResponse.successfullyUpdated &&
          updateMetaOneResponse.hasOwnProperty('message')
        ) {
          navigate(
            `/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYREVIEW}`,
            {
              replace: true,
              state: location?.state,
            }
          );
        }
      }
    } catch (errorData) {
      console.log('Error Updating Survey Information', errorData);
    }
  };

  const handleExitSurvey = () => {
    navigate(`/${NavigationPaths.APPCORE}`, { replace: true });
  };

  return (
    <div className='surveys surveys-performer'>
      <MenuHeader
        screenTitle='Survey'
        screenDescription={location?.state?.surveyTitle ?? `N/A`}
      />

      <div className='surveys-meta'>
        {surveyInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {surveyInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.udiseId}
                  </span>
                </div>
              )}

              {surveyInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.schoolName}
                  </span>
                </div>
              )}

              {surveyInformation?.subject && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Subject</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.subject?.subject}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {surveyInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.district}
                  </span>
                </div>
              )}

              {surveyInformation?.className && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Class</span>
                  <span className='tabular-info-description'>
                    {`${surveyInformation?.className?.className}${
                      surveyInformation?.section ?? ''
                    }`}
                  </span>
                </div>
              )}

              {surveyInformation?.teacher && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Teacher</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.teacher?.teacherName}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='surveys-content'>
        <div className='surveys-content-uploads'>
          <h3 className='surveys-content-subtitle'>Upload Photos</h3>

          <UploadArea
            photoOne={photoOne}
            photoTwo={photoTwo}
            handleUploadPhoto={handleUploadPhoto}
            handleRemovePhoto={handleRemovePhoto}
          />

          <ul className='surveys-content-description'>
            <li>
              Photo upload is <strong>optional</strong>.
            </li>
            <li>Only JPG and PNG files are accepted.</li>
            <li>Maximum size of 5 MB per file is allowed.</li>
          </ul>
        </div>

        <div className='surveys-content-info'>
          <TextInput
            maxLength='50'
            label='Surveyor Name'
            id='surveyEducatorName'
            name='surveyEducatorName'
            onChange={handleFieldUpdate}
            placeholder={`Surveyor's Full Name`}
          />

          <DropDown
            label='Surveyor Designation'
            onChange={handleFieldUpdate}
            id='surveyEducatorDesignation'
            name='surveyEducatorDesignation'
            renderingOptions={EducatorDesignations}
            placeholder='Choose Surveyor Designation'
          />
        </div>

        <div className='surveys-content-options'>
          <button
            type='button'
            onClick={() => handleExitSurvey()}
            className='link-cta link-cta-fullwidth link-cta-centered'
          >
            Exit Survey
          </button>

          <button
            type='button'
            disabled={continueButtonDisabled}
            onClick={() => handleContinueAndProceed()}
            className='primary-cta primary-cta-fullwidth primary-cta-centered'
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveysMetaProofing;
