import React, { useEffect } from 'react';

import CrossIconGrey from 'assets/images/cross-icon-grey.svg';

const FeatureModal = (props) => {
  useEffect(() => {
    document.body.classList.add('noscroll');

    return () => {
      document.body.classList.remove('noscroll');
    };
  }, []);

  return (
    <div className='feature-modal'>
      <div className='feature-modal-workarea'>
        <div className='feature-modal-header'>
          {props.headerChildren}

          <button
            type='button'
            className='feature-modal-close'
            onClick={(event) => props.handleClose(event)}
          >
            <img src={CrossIconGrey} className='close-icon' alt='Close' />
          </button>
        </div>

        <div className='feature-modal-content'>{props.contentChildren}</div>

        <button
          type='button'
          disabled={props.buttonDisabled}
          onClick={() => props.handleSubmit()}
          className='primary-cta primary-cta-fullwidth primary-cta-centered'
        >
          {props.actionText}
        </button>
      </div>
    </div>
  );
};

FeatureModal.defaultProps = {
  actionText: 'Submit',
  buttonDisabled: false,
  handleSubmit: () => {},
};

export default FeatureModal;
