import React, { useEffect, useState } from 'react';
import MenuHeader from 'components/MenuHeader';

import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';
import { getSchoolProfile } from 'services/onboardingService';
import { getMonitoringStatus } from 'services/appCoreService';
import {
  ExamPortalSurveys,
  SurveyPortalSurveys,
  SurveyTypes,
} from 'common/masters';

const baselineSurveyDisabled =
  process.env.REACT_APP_BASELINE_SURVEY_DISABLED === 'true';

const Dashboard = (props) => {
  const navigate = useNavigate();

  const [monitoringOptions, setMonitoringOptions] = useState([]);
  const [schoolInformation, setSchoolInformation] = useState(null);

  useEffect(() => {
    getSchoolInformation();
    getMonitoringOptions();

    // eslint-disable-next-line
  }, []);

  const handleCompleteProfile = () => {
    navigate(NavigationPaths.APPCOREPROFILE);
  };

  const getSchoolInformation = async () => {
    try {
      const schoolProfileResponse = await getSchoolProfile();

      if (schoolProfileResponse) {
        if (Object.keys(schoolProfileResponse).length !== 0) {
          setSchoolInformation(schoolProfileResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching School Profile', errorData);
    }
  };

  const getMonitoringOptions = async () => {
    try {
      const monitoringStatusResponse = await getMonitoringStatus();

      if (monitoringStatusResponse) {
        if (monitoringStatusResponse.hasOwnProperty('surveys')) {
          if (
            Array.isArray(monitoringStatusResponse?.surveys) &&
            monitoringStatusResponse?.surveys?.length !== 0
          ) {
            setMonitoringOptions(monitoringStatusResponse?.surveys);
          }
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Monitoring Status', errorData);
    }
  };

  const getRenderingSurveys = () => {
    let examPortalOptions = [];
    let surveyPortalOptions = [];

    if (
      monitoringOptions &&
      Array.isArray(monitoringOptions) &&
      monitoringOptions.length !== 0
    ) {
      monitoringOptions.forEach(
        (currentMonitoringOption, currentMonitoringOptionIndex) => {
          if (ExamPortalSurveys.includes(currentMonitoringOption?.surveyType)) {
            examPortalOptions.push(
              <div
                className={
                  currentMonitoringOption?.completedSurveyCount >=
                    currentMonitoringOption?.allowedSurveyCount ||
                  (currentMonitoringOption?.surveyType ===
                    SurveyTypes.readinessBaseline &&
                    baselineSurveyDisabled)
                    ? 'status-box status-box-disabled'
                    : 'status-box'
                }
                key={currentMonitoringOptionIndex}
              >
                <h6 className='status-box-heading'>
                  {currentMonitoringOption.surveyTitle}
                </h6>

                <div className='status-box-readings'>
                  <span className='status-box-counts'>{`${
                    currentMonitoringOption.completedSurveyCount ?? 'N/A'
                  } / ${
                    currentMonitoringOption.allowedSurveyCount ?? 'N/A'
                  }`}</span>
                  <span className='status-box-completed'>completed</span>
                </div>

                <button
                  type='button'
                  disabled={
                    currentMonitoringOption?.completedSurveyCount >=
                      currentMonitoringOption?.allowedSurveyCount ||
                    (currentMonitoringOption?.surveyType ===
                      SurveyTypes.readinessBaseline &&
                      baselineSurveyDisabled)
                  }
                  className='link-cta link-cta-withcareticon'
                  onClick={() => handleStartSurvey(currentMonitoringOption)}
                >
                  Take Survey
                </button>
              </div>
            );
          } else if (
            SurveyPortalSurveys.includes(currentMonitoringOption?.surveyType)
          ) {
            surveyPortalOptions.push(
              <div
                className={
                  currentMonitoringOption?.completedSurveyCount >=
                  currentMonitoringOption?.allowedSurveyCount
                    ? 'status-box status-box-disabled'
                    : 'status-box'
                }
                key={currentMonitoringOptionIndex}
              >
                <h6 className='status-box-heading'>
                  {currentMonitoringOption.surveyTitle}
                </h6>

                <div className='status-box-readings'>
                  <span className='status-box-counts'>{`${
                    currentMonitoringOption.completedSurveyCount ?? 'N/A'
                  } / ${
                    currentMonitoringOption.allowedSurveyCount ?? 'N/A'
                  }`}</span>
                  <span className='status-box-completed'>completed</span>
                </div>

                <button
                  type='button'
                  disabled={
                    currentMonitoringOption?.completedSurveyCount >=
                    currentMonitoringOption?.allowedSurveyCount
                  }
                  className='link-cta link-cta-withcareticon'
                  onClick={() => handleStartSurvey(currentMonitoringOption)}
                >
                  Take Survey
                </button>
              </div>
            );
          } else {
            return <></>;
          }

          return <></>;
        }
      );
    }

    return { examPortalOptions, surveyPortalOptions };
  };

  const handleStartSurvey = (selectedSurveyData) => {
    if (
      selectedSurveyData?.completedSurveyCount >=
      selectedSurveyData?.allowedSurveyCount
    ) {
      console.log('Action Not Permitted');

      return;
    }

    const transferStateObject = {
      ...selectedSurveyData,
    };

    delete transferStateObject.allowedSurveyCount;
    delete transferStateObject.completedSurveyCount;

    navigate(`${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYBEGIN}`, {
      state: transferStateObject,
    });
  };

  const handleSeePastSurveys = () => {
    navigate(NavigationPaths.SURVEY);
  };

  return (
    <div className='dashboard'>
      <MenuHeader screenTitle='Dashboard' />

      <p className='dashboard-subtext'>
        You can find the status of monitoring of your school here. Click on the
        survey that you want to take.
      </p>

      <div className='dashboard-meta'>
        {schoolInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {schoolInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.udiseId}
                  </span>
                </div>
              )}

              {schoolInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.schoolName}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {schoolInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.district}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='dashboard-content'>
        <div className='dashboard-content-profile'>
          <button
            className='link-cta link-cta-withcareticon'
            onClick={() => handleCompleteProfile()}
          >
            Update School Profile
          </button>
        </div>

        <div className='dashboard-content-status'>
          <div className='dashboard-content-subsection'>
            <h4 className='dashboard-content-subheading'>Exam Portal</h4>

            {getRenderingSurveys().examPortalOptions}
          </div>

          <div className='dashboard-content-subsection'>
            <h4 className='dashboard-content-subheading'>Survey Portal</h4>

            {getRenderingSurveys().surveyPortalOptions}
          </div>
        </div>

        <button
          type='button'
          onClick={() => handleSeePastSurveys()}
          className='secondary-cta secondary-cta-fullwidth secondary-cta-centered'
        >
          See Past Surveys
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
