import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  getSurveyInformation,
  getSurveyQuestion,
  submitSurveyAnswer,
} from 'services/surveyService';
import { useLocation, useNavigate } from 'react-router-dom';

import MenuHeader from 'components/MenuHeader';
import AnswerCollector from 'components/AnswersCollector';
import ProgressQuestion from 'components/ProgressQuestion';
import QuestionDescriber from 'components/QuestionDescriber';
import { NavigationPaths } from 'common/constants';

const SurveysPerformer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [surveyInformation, setSurveyInformation] = useState(null);
  const [currentlyActiveQuestion, setCurrentlyActiveQuestion] = useState(null);
  const [currentQuestionAnswers, setCurrentQuestionAnswers] = useState([]);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

  useEffect(() => {
    getSurveyDetails();
    getSurveyActiveQuestion();
  }, []);

  useEffect(() => {
    if (
      currentlyActiveQuestion &&
      typeof currentlyActiveQuestion === 'object'
    ) {
      if (
        Object.keys(currentlyActiveQuestion).length === 1 &&
        currentlyActiveQuestion.hasOwnProperty('questionInfo')
      ) {
        if (
          currentlyActiveQuestion?.questionInfo?.questionIndex ===
          currentlyActiveQuestion?.questionInfo?.totalQuestion
        ) {
          navigate(
            `/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYMETA}`,
            {
              replace: true,
              state: location?.state,
            }
          );
        }
      }
    }
  }, [currentlyActiveQuestion]);

  useLayoutEffect(() => {
    let formValid = true;

    if (currentQuestionAnswers.length === 0) {
      formValid = false;
    }

    if (formValid) {
      setContinueButtonDisabled(false);
    } else {
      setContinueButtonDisabled(true);
    }
  }, [currentQuestionAnswers]);

  const getSurveyDetails = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyInformation(dataPayload);

      if (surveyInformationResponse) {
        if (Object.keys(surveyInformationResponse).length !== 0) {
          setSurveyInformation(surveyInformationResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const getSurveyActiveQuestion = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyQuestionResponse = await getSurveyQuestion(dataPayload);

      if (surveyQuestionResponse) {
        if (Object.keys(surveyQuestionResponse).length !== 0) {
          setCurrentlyActiveQuestion(surveyQuestionResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Active Question', errorData);
    }
  };

  const getRenderingQuestionPercentage = () => {
    // Start from 1% to Show Tiny Filled Effect as Fallback
    let renderingPercentage = '1%';

    if (currentlyActiveQuestion) {
      if (currentlyActiveQuestion.hasOwnProperty('questionInfo')) {
        const { questionInfo } = currentlyActiveQuestion;

        // If Answered Question Index = 0 then Show 1, Else Show Index + 1
        const dividend =
          questionInfo.questionIndex === 0 ? 1 : questionInfo.questionIndex + 1;

        const divisor = questionInfo.totalQuestion;

        renderingPercentage = `${parseFloat(
          ((dividend / divisor) * 100).toFixed(3)
        )}%`;
      }
    }

    return renderingPercentage;
  };

  const handleResetStates = () => {
    setCurrentQuestionAnswers([]);
  };

  const handleAnswerUpdate = (fieldIdentifier, updatedAnswersArray) => {
    setCurrentQuestionAnswers(updatedAnswersArray);
  };

  const handleContinueAndProceed = async () => {
    try {
      const dataPayload = {
        answer: currentQuestionAnswers,
        surveyId: location?.state?.surveyId,
        questionId: currentlyActiveQuestion._id,
        questionType: currentlyActiveQuestion.questionType,
      };

      const submitAnswerResponse = await submitSurveyAnswer(dataPayload);

      if (submitAnswerResponse) {
        if (
          submitAnswerResponse.successfullySubmitted &&
          submitAnswerResponse.hasOwnProperty('message')
        ) {
          handleResetStates();
          getSurveyActiveQuestion();
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Active Question', errorData);
    }
  };

  const handleExitSurvey = () => {
    navigate(`/${NavigationPaths.APPCORE}`, { replace: true });
  };

  return (
    <div className='surveys surveys-performer'>
      <MenuHeader
        screenTitle='Survey'
        screenDescription={location?.state?.surveyTitle ?? `N/A`}
      />

      <p className='surveys-subtext'>
        Observe the teachers in class. Select the most appropriate response to
        the comments.
      </p>

      <div className='surveys-meta'>
        {surveyInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {surveyInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.udiseId}
                  </span>
                </div>
              )}

              {surveyInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.schoolName}
                  </span>
                </div>
              )}

              {surveyInformation?.subject && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Subject</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.subject?.subject}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {surveyInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.district}
                  </span>
                </div>
              )}

              {surveyInformation?.className && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Class</span>
                  <span className='tabular-info-description'>
                    {`${surveyInformation?.className?.className}${
                      surveyInformation?.section ?? ''
                    }`}
                  </span>
                </div>
              )}

              {surveyInformation?.teacher && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Teacher</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.teacher?.teacherName}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='surveys-content'>
        {currentlyActiveQuestion && (
          <ProgressQuestion
            questionsData={{
              currentQuestion:
                currentlyActiveQuestion.questionInfo.questionIndex === 0
                  ? 1
                  : currentlyActiveQuestion.questionInfo.questionIndex + 1,
              totalQuestions:
                currentlyActiveQuestion.questionInfo.totalQuestion,
            }}
            filledProgress={getRenderingQuestionPercentage()}
          />
        )}

        {currentlyActiveQuestion && (
          <div className='surveys-content-questions'>
            <QuestionDescriber
              questionData={{
                questionTitle: currentlyActiveQuestion?.questionTitle,
                questionDescription:
                  currentlyActiveQuestion?.questionDescription,
              }}
            />

            <AnswerCollector
              handleAnswerUpdate={handleAnswerUpdate}
              surveyType={location?.state?.surveyType}
              selectedAnswers={currentQuestionAnswers}
              questionType={currentlyActiveQuestion?.questionType}
              possibleAnswers={currentlyActiveQuestion?.possibleAnswersArray}
            />
          </div>
        )}

        <div className='surveys-content-options'>
          <button
            type='button'
            onClick={() => handleExitSurvey()}
            className='link-cta link-cta-fullwidth link-cta-centered'
          >
            Exit Survey
          </button>

          <button
            type='button'
            disabled={continueButtonDisabled}
            onClick={() => handleContinueAndProceed()}
            className='primary-cta primary-cta-fullwidth primary-cta-centered'
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveysPerformer;
