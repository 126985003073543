import { APIPaths } from 'common/constants';
import { apiInstance, mediaApiInstance } from 'services/createService';

export const createNewSurvey = async (dataPayload) => {
  return await apiInstance.post(APIPaths.CREATESURVEY, dataPayload);
};

export const getSurveyInformation = async (dataPayload) => {
  return await apiInstance.post(APIPaths.GETSURVEYINFORMATION, dataPayload);
};

export const getSurveyQuestion = async (dataPayload) => {
  return await apiInstance.post(APIPaths.GETSURVEYQUESTION, dataPayload);
};

export const submitSurveyAnswer = async (dataPayload) => {
  return await apiInstance.post(APIPaths.SUBMITSURVEYANSWER, dataPayload);
};

export const updateSurveyMetaOne = async (dataPayload) => {
  return await apiInstance.put(APIPaths.UPDATEMETADATAONE, dataPayload);
};

export const getSurveyMedia = async (dataPayload) => {
  return await apiInstance.post(APIPaths.GETMEDIA, dataPayload);
};

export const uploadSurveyMedia = async (dataPayload) => {
  return await mediaApiInstance.post(APIPaths.UPLOADMEDIA, dataPayload);
};

export const removeSurveyMedia = async (dataPayload) => {
  return await apiInstance.delete(APIPaths.REMOVEMEDIA, dataPayload);
};

export const updateSurveyMetaTwo = async (dataPayload) => {
  return await apiInstance.put(APIPaths.UPDATEMETADATATWO, dataPayload);
};

export const getSurveyResponses = async (dataPayload) => {
  return await apiInstance.post(APIPaths.GETSURVEYRESPONSES, dataPayload);
};

export const updateSurveyMetaThree = async (dataPayload) => {
  return await apiInstance.put(APIPaths.UPDATEMETADATATHREE, dataPayload);
};

export const getSurveyInformationById = async (dataPayload) => {
  return await apiInstance.post(APIPaths.GETSURVEYBYID, dataPayload);
};
