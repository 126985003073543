import axios from 'axios';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_NEEVE_API_BASE_PATH,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (requestConfig) => {
    const authenticationToken = sessionStorage.getItem('mappToken');

    if (authenticationToken) {
      requestConfig.headers.Authorization = authenticationToken;
    }

    return requestConfig;
  },
  (requestError) => {
    console.log('----------- BEGIN REQUEST ERROR -----------');
    console.log(requestError);
    console.log('----------- END REQUEST ERROR -----------');

    return Promise.reject(requestError);
  }
);

apiInstance.interceptors.response.use(
  (responseData) => {
    if (responseData.data.hasOwnProperty('data')) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }
  },
  (responseError) => {
    console.log('----------- BEGIN RESPONSE ERROR -----------');
    console.log(responseError);
    console.log('----------- END RESPONSE ERROR -----------');

    return Promise.reject(responseError);
  }
);

const mediaApiInstance = axios.create({
  baseURL: process.env.REACT_APP_NEEVE_API_BASE_PATH,
  timeout: 60000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

mediaApiInstance.interceptors.request.use(
  (requestConfig) => {
    const authenticationToken = sessionStorage.getItem('mappToken');

    if (authenticationToken) {
      requestConfig.headers.Authorization = authenticationToken;
    }

    return requestConfig;
  },
  (requestError) => {
    console.log('----------- BEGIN REQUEST ERROR -----------');
    console.log(requestError);
    console.log('----------- END REQUEST ERROR -----------');

    return Promise.reject(requestError);
  }
);

mediaApiInstance.interceptors.response.use(
  (responseData) => {
    if (responseData.data.hasOwnProperty('data')) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }
  },
  (responseError) => {
    console.log('----------- BEGIN RESPONSE ERROR -----------');
    console.log(responseError);
    console.log('----------- END RESPONSE ERROR -----------');

    return Promise.reject(responseError);
  }
);

export { apiInstance, mediaApiInstance };
