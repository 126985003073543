import React, { useEffect, useState } from 'react';
import {
  dropDownFriendlyClasses,
  dropDownFriendlySubjects,
} from 'common/parsers';

import MenuHeader from 'components/MenuHeader';
import TeacherRecord from 'components/TeacherRecord';

import {
  getClassesAndSubjects,
  getSchoolProfile,
} from 'services/onboardingService';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

const ProfileStaticView = (props) => {
  const navigate = useNavigate();

  const [classData, setClassData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [schoolInformation, setSchoolInformation] = useState(null);

  useEffect(() => {
    getSchoolInformation();
    getClassesAndSubjectsForRendering();

    // eslint-disable-next-line
  }, []);

  const getSchoolInformation = async () => {
    try {
      const schoolProfileResponse = await getSchoolProfile();

      if (schoolProfileResponse) {
        if (Object.keys(schoolProfileResponse).length !== 0) {
          setSchoolInformation(schoolProfileResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching School Profile', errorData);
    }
  };

  const getClassesAndSubjectsForRendering = async () => {
    try {
      const getClassesResponse = await getClassesAndSubjects();

      if (getClassesResponse) {
        const convertedClassesForRendering = dropDownFriendlyClasses(
          getClassesResponse.classes
        );
        const convertedSubjectsForRendering = dropDownFriendlySubjects(
          getClassesResponse.subjects
        );

        setClassData(convertedClassesForRendering);
        setSubjectsData(convertedSubjectsForRendering);
      }
    } catch (errorData) {
      console.log('Error Fetching Class Details', errorData);
    }
  };

  const handleEditTeachers = () => {
    navigate(NavigationPaths.APPCOREPROFILETEACHERS);
  };

  return (
    <div className='profile'>
      <MenuHeader screenTitle='School Profile' />

      <p className='profile-subtext'>
        Here is your school profile. This information cannot be changed, however
        you can add or remove teachers using the Manage Teachers option below.
      </p>

      <div className='profile-meta'>
        {schoolInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {schoolInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.udiseId}
                  </span>
                </div>
              )}

              {schoolInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.schoolName}
                  </span>
                </div>
              )}

              {schoolInformation?.schoolEnroll && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Enrollment</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.schoolEnroll}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {schoolInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.district}
                  </span>
                </div>
              )}

              {schoolInformation?.lowestClass &&
                schoolInformation?.highestClass && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Class Range</span>
                    <span className='tabular-info-description'>
                      {`${schoolInformation?.lowestClass?.className} to ${schoolInformation?.highestClass?.className}`}
                    </span>
                  </div>
                )}

              {schoolInformation?.teachers &&
                Array.isArray(schoolInformation?.teachers) && (
                  <div className='tabular-info-line'>
                    <span className='tabular-info-heading'>Total Teachers</span>
                    <span className='tabular-info-description'>
                      {schoolInformation?.teachers?.length}
                    </span>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>

      <div className='profile-content'>
        {schoolInformation?.teachers &&
        Array.isArray(schoolInformation?.teachers) &&
        schoolInformation?.teachers.length !== 0 ? (
          <div className='auth-teacher-records'>
            {schoolInformation?.teachers.map(
              (currentTeacherObject, currentTeacherObjectIndex) => {
                return (
                  <TeacherRecord
                    readOnly={true}
                    classData={classData}
                    subjectsData={subjectsData}
                    key={currentTeacherObjectIndex}
                    recordData={currentTeacherObject}
                    recordIndex={currentTeacherObjectIndex + 1}
                  />
                );
              }
            )}
          </div>
        ) : (
          <div className='auth-teacher-records auth-teacher-recordsempty'>
            No Teachers Added
          </div>
        )}

        <button
          type='button'
          onClick={() => handleEditTeachers()}
          className='secondary-cta secondary-cta-fullwidth secondary-cta-centered'
        >
          Add / Remove Teachers
        </button>
      </div>
    </div>
  );
};

export default ProfileStaticView;
