import React from 'react';

import CaretLeft from 'assets/images/caret-left.svg';

const ProgressHeader = (props) => {
  return (
    <div className='progress-header'>
      {!props.disableBackButton && (
        <button type='button' className='progress-header-back'>
          <img src={CaretLeft} alt='Back' className='progress-header-caret' />
        </button>
      )}

      <div className='progress-header-track'>
        <div
          className='progress-header-bar'
          style={{ width: props.filledProgress }}
        />
      </div>
    </div>
  );
};

export default ProgressHeader;
