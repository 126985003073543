import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  getSurveyInformation,
  updateSurveyMetaOne,
} from 'services/surveyService';
import { SurveyTypes } from 'common/masters';
import { NavigationPaths } from 'common/constants';
import { useLocation, useNavigate } from 'react-router-dom';

import TextArea from 'components/TextArea';
import TextInput from 'components/TextInput';
import MenuHeader from 'components/MenuHeader';

const SurveysMetaInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState({
    surveyMetaEnrollment: '',
    surveyMetaAttendance: '',
    surveyMetaComments: '',
  });
  const [surveyInformation, setSurveyInformation] = useState(null);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

  useEffect(() => {
    getSurveyDetails();
  }, []);

  useLayoutEffect(() => {
    let formValid = true;

    if (
      location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
      location?.state?.surveyType !== SurveyTypes.readinessEndline &&
      location?.state?.surveyType !== SurveyTypes.term1Exam &&
      location?.state?.surveyType !== SurveyTypes.term2Exam &&
      location?.state?.surveyType !== SurveyTypes.term3Exam &&
      location?.state?.surveyType !== SurveyTypes.term4Exam &&
      location?.state?.surveyType !== SurveyTypes.halfye &&
      location?.state?.surveyType !== SurveyTypes.finalExam
    ) {
      Object.keys(formFields).forEach((currentKey) => {
        if (
          formFields[currentKey] === '' ||
          formFields[currentKey] === null ||
          formFields[currentKey] === undefined
        ) {
          formValid = false;
        }
      });
    } else {
      if (
        (formFields['surveyMetaAttendance'] === '' ||
          formFields['surveyMetaAttendance'] === null ||
          formFields['surveyMetaAttendance'] === undefined) &&
        (formFields['surveyMetaComments'] === '' ||
          formFields['surveyMetaComments'] === null ||
          formFields['surveyMetaComments'] === undefined)
      ) {
        formValid = false;
      }
    }

    if (formValid) {
      setContinueButtonDisabled(false);
    } else {
      setContinueButtonDisabled(true);
    }
  }, [formFields]);

  const getSurveyDetails = async () => {
    try {
      const dataPayload = {
        surveyId: location?.state?.surveyId,
        surveyType: location?.state?.surveyType,
      };

      const surveyInformationResponse = await getSurveyInformation(dataPayload);

      if (surveyInformationResponse) {
        if (Object.keys(surveyInformationResponse).length !== 0) {
          setSurveyInformation(surveyInformationResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching Survey Information', errorData);
    }
  };

  const handleFieldUpdate = (fieldIdentifier, fieldValue) => {
    let updatedFormFields = Object.assign({}, formFields);

    updatedFormFields[`${fieldIdentifier}`] = fieldValue;

    setFormFields(updatedFormFields);
  };

  const handleContinueAndProceed = async () => {
    try {
      let dataPayload = {};

      if (
        location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
        location?.state?.surveyType !== SurveyTypes.readinessEndline &&
        location?.state?.surveyType !== SurveyTypes.term1Exam &&
        location?.state?.surveyType !== SurveyTypes.term2Exam &&
        location?.state?.surveyType !== SurveyTypes.term3Exam &&
        location?.state?.surveyType !== SurveyTypes.term4Exam &&
        location?.state?.surveyType !== SurveyTypes.halfye &&
        location?.state?.surveyType !== SurveyTypes.finalExam
      ) {
        dataPayload = {
          surveyId: location?.state?.surveyId,
          surveyType: location?.state?.surveyType,
          enrollment: formFields.surveyMetaEnrollment,
          attendance: formFields.surveyMetaAttendance,
          comment: formFields.surveyMetaComments,
        };
      } else {
        dataPayload = {
          surveyId: location?.state?.surveyId,
          surveyType: location?.state?.surveyType,
          enrollment: formFields.surveyMetaEnrollment,
          comment: formFields.surveyMetaComments,
        };
      }

      const updateMetaOneResponse = await updateSurveyMetaOne(dataPayload);

      if (updateMetaOneResponse) {
        if (
          updateMetaOneResponse.successfullyUpdated &&
          updateMetaOneResponse.hasOwnProperty('message')
        ) {
          navigate(
            `/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYPROOFING}`,
            {
              replace: true,
              state: location?.state,
            }
          );
        }
      }
    } catch (errorData) {
      console.log('Error Updating Survey Information', errorData);
    }
  };

  const handleExitSurvey = () => {
    navigate(`/${NavigationPaths.APPCORE}`, { replace: true });
  };

  return (
    <div className='surveys surveys-performer'>
      <MenuHeader
        screenTitle='Survey'
        screenDescription={location?.state?.surveyTitle ?? `N/A`}
      />

      <div className='surveys-meta'>
        {surveyInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {surveyInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.udiseId}
                  </span>
                </div>
              )}

              {surveyInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.schoolName}
                  </span>
                </div>
              )}

              {surveyInformation?.subject && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Subject</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.subject?.subject}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {surveyInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.district}
                  </span>
                </div>
              )}

              {surveyInformation?.className && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Class</span>
                  <span className='tabular-info-description'>
                    {`${surveyInformation?.className?.className}${
                      surveyInformation?.section ?? ''
                    }`}
                  </span>
                </div>
              )}

              {surveyInformation?.teacher && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>Teacher</span>
                  <span className='tabular-info-description'>
                    {surveyInformation?.teacher?.teacherName}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='surveys-content'>
        <div className='surveys-content-info'>
          <TextInput
            type='number'
            maxLength='5'
            label='Enrollment'
            id='surveyMetaEnrollment'
            name='surveyMetaEnrollment'
            onChange={handleFieldUpdate}
            placeholder='Enrollment of this Class'
          />

          {location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
            location?.state?.surveyType !== SurveyTypes.readinessEndline &&
            location?.state?.surveyType !== SurveyTypes.term1Exam &&
            location?.state?.surveyType !== SurveyTypes.term2Exam &&
            location?.state?.surveyType !== SurveyTypes.term3Exam &&
            location?.state?.surveyType !== SurveyTypes.term4Exam &&
            location?.state?.surveyType !== SurveyTypes.halfye &&
            location?.state?.surveyType !== SurveyTypes.finalExam && (
              <TextInput
                type='number'
                maxLength='5'
                label='Attendance'
                placeholder='Attendance'
                id='surveyMetaAttendance'
                name='surveyMetaAttendance'
                onChange={handleFieldUpdate}
              />
            )}

          <TextArea
            maxWords='100'
            label='Comments'
            placeholder='Comments'
            id='surveyMetaComments'
            name='surveyMetaComments'
            onChange={handleFieldUpdate}
          />
        </div>

        <div className='surveys-content-options'>
          <button
            type='button'
            onClick={() => handleExitSurvey()}
            className='link-cta link-cta-fullwidth link-cta-centered'
          >
            Exit Survey
          </button>

          <button
            type='button'
            disabled={continueButtonDisabled}
            onClick={() => handleContinueAndProceed()}
            className='primary-cta primary-cta-fullwidth primary-cta-centered'
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveysMetaInfo;
