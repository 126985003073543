import { APIPaths } from 'common/constants';
import { apiInstance } from 'services/createService';

export const getClassesAndSubjects = async () => {
  return await apiInstance.get(APIPaths.GETCLASSESANDSUBJECTS);
};

export const updateSchoolProfile = async (dataPayload) => {
  return await apiInstance.post(APIPaths.UPDATESCHOOLPROFILE, dataPayload);
};

export const getSchoolProfile = async () => {
  return await apiInstance.get(APIPaths.GETSCHOOLPROFILE);
};

export const getAllTeachersForSchool = async () => {
  return await apiInstance.get(APIPaths.GETALLTEACHERSFORSCHOOL);
};

export const addSingleTeacher = async (dataPayload) => {
  return await apiInstance.post(APIPaths.ADDSINGLETEACHER, dataPayload);
};

export const removeSingleTeacher = async (dataPayload) => {
  return await apiInstance.delete(APIPaths.REMOVESINGLETEACHER, dataPayload);
};

export const finalSubmitOnboarding = async () => {
  return await apiInstance.put(APIPaths.FINALSUBMITONBOARDING);
};
