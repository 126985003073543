import React, { useState } from 'react';

const TextArea = (props) => {
  // Handle Pre-Assignment of Data from Here
  const [wordCount, setWordCount] = useState(0);
  const [fieldValue, setFieldValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleFieldBoundaryClick = (event) => {
    const targetClassName = event?.target?.className;

    if (targetClassName && targetClassName.split(' ').includes('form-field')) {
      document.getElementById(props.id).focus();
    }
  };

  const getRenderingFieldStates = () => {
    let fieldClasses = ['form-field', 'form-textarea'];

    if (!!props.rightAction) {
      fieldClasses.push('form-field-actionable');
    }

    if (isFocused) {
      fieldClasses.push('form-field-focused');
    }

    if (fieldValue && fieldValue !== '' && !isFocused) {
      fieldClasses.push('form-field-dirty');
    }

    return fieldClasses.join(' ');
  };

  const handleFieldValueUpdate = (event) => {
    const enteredValue = event.target.value;

    if (enteredValue.split(' ').length <= props.maxWords) {
      setWordCount(enteredValue.split(' ').length);
      setFieldValue(enteredValue);

      // Call Handler Callback from Here
      props.onChange(props.id, enteredValue);
    }
  };

  return (
    <div
      onClick={handleFieldBoundaryClick}
      className={getRenderingFieldStates()}
    >
      <label className='form-field-label'>{props.label}</label>

      <textarea
        rows={5}
        id={props.id}
        name={props.name}
        value={fieldValue}
        className='form-field-input'
        placeholder={props.placeholder}
        onChange={handleFieldValueUpdate}
        onBlur={() => setIsFocused((currentState) => !currentState)}
        onFocus={() => setIsFocused((currentState) => !currentState)}
      />

      <span className='form-textarea-wordcount'>{`${wordCount}/${props.maxWords} words`}</span>
    </div>
  );
};

TextArea.defaultProps = {
  onChange: () => {},
};

export default TextArea;
