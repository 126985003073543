import React, { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import NeeveLogo from 'assets/images/neeve-logo.svg';

const SplashLanding = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem('mappToken') !== null) {
      sessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (queryParams.has('udise') && queryParams.has('auth_token')) {
      setButtonDisabled(false);
    }
  }, [queryParams]);

  const handleProceed = () => {
    navigate(NavigationPaths.ONBOARDING, {
      replace: true,
      state: {
        udiseCode: queryParams.get('udise'),
        authToken: queryParams.get('auth_token'),
      },
    });
  };

  return (
    <>
      <div className='highlight-pattern' />

      <div className='splash-landing'>
        <div className='splash-landing-content'>
          <img src={NeeveLogo} alt='Neeve - Freedom of Learning' />

          <h1 className='splash-landing-title'>Neeve Monitoring Portal</h1>
        </div>

        <div className='splash-landing-footer'>
          <button
            type='button'
            disabled={buttonDisabled}
            className='primary-cta primary-cta-fullwidth'
            onClick={() => handleProceed()}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default SplashLanding;
