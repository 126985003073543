import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import CrossIconGrey from 'assets/images/cross-icon-grey.svg';

const MenuOverlay = (props) => {
  const { handleClose } = props;

  const navigate = useNavigate();

  const handleNavigation = (navigationTo) => {
    if (navigationTo === 'dashboard') {
      navigate(`/${NavigationPaths.APPCORE}`, { replace: true });
    } else if (navigationTo === 'pastSurveys') {
      navigate(`/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}`, {
        replace: true,
      });
    } else {
      navigate(
        `/${NavigationPaths.APPCORE}/${NavigationPaths.APPCOREPROFILE}`,
        {
          replace: true,
        }
      );
    }

    handleClose(false);
  };

  return (
    <div className='menu-overlay'>
      <div className='menu-overlay-wrapper'>
        <div className='menu-overlay-header'>
          <h4 className='menu-overlay-title'>Menu</h4>

          <button
            type='button'
            className='menu-overlay-close'
            onClick={() => handleClose(false)}
          >
            <img
              alt='Close'
              src={CrossIconGrey}
              className='menu-overlay-closeicon'
            />
          </button>
        </div>

        <div className='menu-overlay-options'>
          <button
            type='button'
            className='menu-overlay-option'
            onClick={() => handleNavigation('dashboard')}
          >
            Dashboard
          </button>

          <button
            type='button'
            className='menu-overlay-option'
            onClick={() => handleNavigation('pastSurveys')}
          >
            Past Surveys
          </button>

          <button
            type='button'
            className='menu-overlay-option'
            onClick={() => handleNavigation('editProfile')}
          >
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuOverlay;
