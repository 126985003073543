import React from 'react';

const QAReviewSegment = (props) => {
  const { segmentTitle, segmentDescription, segmentAnswer } = props;

  const getRenderingAnswers = () => {
    let renderingElement = <></>;

    if (segmentAnswer) {
      if (segmentAnswer.length > 1) {
        renderingElement = (
          <p className='qareview-segment-answerdescription'>
            <strong>Your Response:</strong>{' '}
            <ol className='qareview-segment-answerlist'>
              {segmentAnswer.map(
                (currentAnswerString, currentAnswerStringIndex) => {
                  return (
                    <>
                      <li key={currentAnswerStringIndex}>
                        {currentAnswerString}
                      </li>
                    </>
                  );
                }
              )}
            </ol>
          </p>
        );
      } else {
        renderingElement = (
          <p className='qareview-segment-answerdescription'>
            <strong>Your Response:</strong> {segmentAnswer[0] ?? 'N/A'}
          </p>
        );
      }
    }

    return renderingElement;
  };

  return (
    <section className='qareview-segment'>
      {segmentTitle && (
        <h3 className='qareview-segment-title'>{segmentTitle}</h3>
      )}

      <p className='qareview-segment-description'>
        <strong>Question:</strong> {segmentDescription ?? 'N/A'}
      </p>

      {getRenderingAnswers()}
    </section>
  );
};

export default QAReviewSegment;
