import React, { useEffect } from 'react';

import { NavigationPaths } from 'common/constants';
import { getNeeveAuth } from 'services/authService';
import { useLocation, useNavigate } from 'react-router-dom';

import GlobalLoader from 'components/GlobalLoader';

const AuthChoice = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();

  useEffect(() => {
    if (typeof routerLocation.state === 'object') {
      if (Object.keys(routerLocation.state ?? {}).length !== 0) {
        // Found Entries in the State - Valid Entry of User
        getAuthenticationFromNeeve();
      }
    }

    // eslint-disable-next-line
  }, []);

  const getAuthenticationFromNeeve = async () => {
    try {
      const dataPayload = {
        params: {
          udiseCode: routerLocation.state.udiseCode,
          authToken: routerLocation.state.authToken,
        },
      };

      const neeveAuthResponse = await getNeeveAuth(dataPayload);

      if (neeveAuthResponse) {
        if (Object.keys(neeveAuthResponse).length) {
          if (neeveAuthResponse.hasOwnProperty('access_token')) {
            sessionStorage.setItem('mappToken', neeveAuthResponse.access_token);

            if (neeveAuthResponse.hasOwnProperty('profileCompleted')) {
              // User has Already Onboarded - Re-Visit Case
              if (neeveAuthResponse?.profileCompleted === true) {
                setTimeout(() => {
                  handleChoice('returning');
                }, 2500);
              } else {
                const transferStateObject = {
                  ...neeveAuthResponse,
                };

                delete transferStateObject._id;
                delete transferStateObject.access_token;

                setTimeout(() => {
                  handleChoice('register', transferStateObject);
                }, 2500);
              }
            }
          }
        }
      }
    } catch (errorData) {
      console.log('Error While Getting User Informaiton', errorData);
    }
  };

  const handleChoice = (selectedPathway, transferState = {}) => {
    // Login Path Unused Due to Removal of Screens from Flow
    if (selectedPathway === 'login') {
      navigate(NavigationPaths.ONBOARDINGLOGIN, { replace: true });
    } else if (selectedPathway === 'returning') {
      // Revisiting User Scenario - Redirect to Dashboard
      navigate(`/${NavigationPaths.APPCORE}`, {
        replace: true,
      });
    } else {
      // First Time Visiting Scenario - Redirect to Onboarding
      navigate(NavigationPaths.ONBOARDINGREGISTER, {
        replace: true,
        state: transferState,
      });
    }
  };

  return (
    <>
      <div className='highlight-pattern' />

      <div className='auth-choice'>
        <div className='auth-choice-content'>
          <h4 className='auth-choice-title'>Welcome!</h4>
          <p className='auth-choice-description'>Are you an existing user?</p>

          <div className='auth-choice-options'>
            <button
              type='button'
              onClick={() => handleChoice('login')}
              className='secondary-cta secondary-cta-fullwidth'
            >
              Yes, Go To Login
            </button>

            <button
              type='button'
              onClick={() => handleChoice('register')}
              className='secondary-cta secondary-cta-fullwidth'
            >
              No, Register Now
            </button>
          </div>
        </div>
      </div>

      <GlobalLoader />
    </>
  );
};

export default AuthChoice;
