import React from 'react';

import CrossIconWhite from 'assets/images/cross-icon-white.svg';

const TeacherRecord = (props) => {
  const getRenderingSubjects = () => {
    let renderingSubjects = [];

    const { recordData } = props;

    if (Object.keys(recordData).length !== 0) {
      recordData.subjects.forEach((currentTeacherSubjectsObject) => {
        renderingSubjects.push(currentTeacherSubjectsObject?.subject);
      });
    }

    return renderingSubjects.join(', ');
  };

  const getRenderingClasses = () => {
    let renderingClasses = [];

    const { recordData } = props;

    if (Object.keys(recordData).length !== 0) {
      recordData.classes.forEach((currentTeacherClassObject) => {
        renderingClasses.push(currentTeacherClassObject?.className);
      });
    }

    return renderingClasses.join(', ');
  };

  return (
    <div className='teacher-record'>
      <div className='teacher-record-header'>
        <h6 className='teacher-record-teachername'>{`Teacher #${props.recordIndex}`}</h6>

        {!props.readOnly && (
          <button
            type='button'
            className='teacher-record-remove'
            onClick={() => props.removeAction(props.recordData._id)}
          >
            <img src={CrossIconWhite} className='cross-icon' alt='Remove' />
          </button>
        )}
      </div>

      <div className='teacher-record-content'>
        <div className='teacher-record-information'>
          <span className='teacher-record-infotitle'>Name</span>
          <span className='teacher-record-infodescription'>{`${props.recordData.teacherName}`}</span>
        </div>

        <div className='teacher-record-information'>
          <span className='teacher-record-infotitle'>Subjects</span>
          <span className='teacher-record-infodescription'>
            {getRenderingSubjects()}
          </span>
        </div>

        <div className='teacher-record-information'>
          <span className='teacher-record-infotitle'>Classes</span>
          <span className='teacher-record-infodescription'>
            {getRenderingClasses()}
          </span>
        </div>
      </div>
    </div>
  );
};

TeacherRecord.defaultProps = {
  readOnly: false,
  recordIndex: 1,
  recordData: {},
};

export default TeacherRecord;
