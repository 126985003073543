import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  dropDownFriendlyClasses,
  dropDownFriendlySubjects,
} from 'common/parsers';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getClassesAndSubjects,
  getSchoolProfile,
} from 'services/onboardingService';
import { NavigationPaths } from 'common/constants';
import { parseTeachersForDropdown } from './parser';
import { createNewSurvey } from 'services/surveyService';
import { SurveyTypes, sectionMaster } from 'common/masters';

import DropDown from 'components/DropDown';
import MenuHeader from 'components/MenuHeader';
import FeatureModal from 'components/FeatureModal';

const SurveysBegin = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [classData, setClassData] = useState([]);
  const [formFields, setFormFields] = useState({
    surveyStartClass: '',
    surveyStartSection: '',
    surveyStartTeacher: '',
  });
  const [subjectsData, setSubjectsData] = useState([]);
  const [schoolInformation, setSchoolInformation] = useState(null);
  const [temporarySelectedSubjects, setTemporarySelectedSubjects] = useState(
    []
  );
  const [startSurveyButtonDisabled, setStartSurveyButtonDisabled] =
    useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  useEffect(() => {
    getSchoolInformation();
    getClassesAndSubjectsForRendering();

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    let formValid = true;

    if (temporarySelectedSubjects.length === 0) {
      formValid = false;
    }

    if (
      location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
      location?.state?.surveyType !== SurveyTypes.readinessEndline &&
      location?.state?.surveyType !== SurveyTypes.term1Exam &&
      location?.state?.surveyType !== SurveyTypes.term2Exam &&
      location?.state?.surveyType !== SurveyTypes.term3Exam &&
      location?.state?.surveyType !== SurveyTypes.term4Exam &&
      location?.state?.surveyType !== SurveyTypes.halfye &&
      location?.state?.surveyType !== SurveyTypes.finalExam
    ) {
      Object.keys(formFields).forEach((currentKey) => {
        if (
          formFields[currentKey] === '' ||
          formFields[currentKey] === null ||
          formFields[currentKey] === undefined
        ) {
          formValid = false;
        }
      });
    } else {
      if (
        formFields['surveyStartClass'] === '' ||
        formFields['surveyStartClass'] === null ||
        formFields['surveyStartClass'] === undefined
      ) {
        formValid = false;
      }
    }

    if (formValid) {
      setStartSurveyButtonDisabled(false);
    } else {
      setStartSurveyButtonDisabled(true);
    }
  }, [formFields, temporarySelectedSubjects]);

  const getSchoolInformation = async () => {
    try {
      const schoolProfileResponse = await getSchoolProfile();

      if (schoolProfileResponse) {
        if (Object.keys(schoolProfileResponse).length !== 0) {
          setSchoolInformation(schoolProfileResponse);
        }
      }
    } catch (errorData) {
      console.log('Error Fetching School Profile', errorData);
    }
  };

  const getClassesAndSubjectsForRendering = async () => {
    try {
      const getClassesResponse = await getClassesAndSubjects();

      if (getClassesResponse) {
        const convertedClassesForRendering = dropDownFriendlyClasses(
          getClassesResponse.classes
        );
        const convertedSubjectsForRendering = dropDownFriendlySubjects(
          getClassesResponse.subjects
        );

        setClassData(convertedClassesForRendering);
        setSubjectsData(convertedSubjectsForRendering);
      }
    } catch (errorData) {
      console.log('Error Fetching Class Details', errorData);
    }
  };

  const handleFieldUpdate = (fieldIdentifier, fieldValue) => {
    let updatedFormFields = Object.assign({}, formFields);

    updatedFormFields[`${fieldIdentifier}`] = fieldValue;

    setFormFields(updatedFormFields);
  };

  const handleToggleSelectedSubject = (selectedSubjectObject) => {
    let alreadySelectedSubject = false;

    if (Object.keys(selectedSubjectObject).length !== 0) {
      alreadySelectedSubject = temporarySelectedSubjects.some(
        (currentTemporarySubjectObject) => {
          if (
            currentTemporarySubjectObject?.value ===
            selectedSubjectObject?.value
          ) {
            return true;
          }
        }
      );

      let updatedTemporarySelectedSubjects = [...temporarySelectedSubjects];

      if (!alreadySelectedSubject) {
        if (updatedTemporarySelectedSubjects.length !== 0) {
          updatedTemporarySelectedSubjects = [];

          updatedTemporarySelectedSubjects.push(selectedSubjectObject);
        } else {
          updatedTemporarySelectedSubjects.push(selectedSubjectObject);
        }
      } else {
        updatedTemporarySelectedSubjects = [];
      }

      setTemporarySelectedSubjects(updatedTemporarySelectedSubjects);
    }
  };

  const getRenderingSubjectButtonClasses = (currentSubjectButtonValue) => {
    let renderingClasses = ['bool-cta'];
    let alreadySelectedSubject = false;

    alreadySelectedSubject = temporarySelectedSubjects.some(
      (currentTemporarySubjectObject) => {
        if (
          currentTemporarySubjectObject?.value === currentSubjectButtonValue
        ) {
          return true;
        }
      }
    );

    if (alreadySelectedSubject) {
      if (renderingClasses.includes('bool-cta-selected')) {
        renderingClasses = renderingClasses.filter(
          (currentRenderingClass) =>
            currentRenderingClass !== 'bool-cta-selected'
        );
      } else {
        renderingClasses.push('bool-cta-selected');
      }
    }

    return renderingClasses.join(' ');
  };

  const handleProceed = async () => {
    let formValid = true;

    if (temporarySelectedSubjects.length === 0) {
      formValid = false;
    }

    if (
      location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
      location?.state?.surveyType !== SurveyTypes.readinessEndline &&
      location?.state?.surveyType !== SurveyTypes.term1Exam &&
      location?.state?.surveyType !== SurveyTypes.term2Exam &&
      location?.state?.surveyType !== SurveyTypes.term3Exam &&
      location?.state?.surveyType !== SurveyTypes.term4Exam &&
      location?.state?.surveyType !== SurveyTypes.halfye &&
      location?.state?.surveyType !== SurveyTypes.finalExam
    ) {
      Object.keys(formFields).forEach((currentKey) => {
        if (
          formFields[currentKey] === '' ||
          formFields[currentKey] === null ||
          formFields[currentKey] === undefined
        ) {
          formValid = false;
        }
      });
    } else {
      if (
        formFields['surveyStartClass'] === '' ||
        formFields['surveyStartClass'] === null ||
        formFields['surveyStartClass'] === undefined
      ) {
        formValid = false;
      }
    }

    if (formValid) {
      try {
        let dataPayload = {};

        if (
          location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
          location?.state?.surveyType !== SurveyTypes.readinessEndline &&
          location?.state?.surveyType !== SurveyTypes.term1Exam &&
          location?.state?.surveyType !== SurveyTypes.term2Exam &&
          location?.state?.surveyType !== SurveyTypes.term3Exam &&
          location?.state?.surveyType !== SurveyTypes.term4Exam &&
          location?.state?.surveyType !== SurveyTypes.halfye &&
          location?.state?.surveyType !== SurveyTypes.finalExam
        ) {
          dataPayload = {
            teacher: formFields.surveyStartTeacher,
            className: formFields.surveyStartClass,
            section: formFields.surveyStartSection,
            surveyType: location?.state?.surveyType,
            subject: temporarySelectedSubjects[0].value,
          };
        } else {
          dataPayload = {
            className: formFields.surveyStartClass,
            surveyType: location?.state?.surveyType,
            subject: temporarySelectedSubjects[0].value,
          };
        }

        const createSurveyResponse = await createNewSurvey(dataPayload);

        if (createSurveyResponse) {
          if (
            createSurveyResponse.hasOwnProperty('surveyId') &&
            createSurveyResponse.hasOwnProperty('surveyType') &&
            createSurveyResponse.hasOwnProperty('existingSurvey')
          ) {
            const transferStateObject = {
              surveyTitle: location?.state?.surveyTitle,
              surveyType: createSurveyResponse.surveyType,
              surveyId: createSurveyResponse.surveyId,
            };

            navigate(
              `/${NavigationPaths.APPCORE}/${NavigationPaths.SURVEY}/${NavigationPaths.SURVEYPERFORMER}`,
              {
                replace: true,
                state: transferStateObject,
              }
            );
          }
        }
      } catch (errorData) {
        console.log('Error Creating Survey', errorData);

        if (errorData?.response?.data?.code === 400) {
          handleErrorDialogToggle();
        }
      }
    }
  };

  const handleErrorDialogToggle = (event) => {
    setErrorDialogVisible((currentState) => !currentState);
  };

  return (
    <div className='surveys surveys-begin'>
      {location?.state && (
        <MenuHeader
          screenTitle='Start Survey'
          screenDescription={location?.state?.surveyTitle ?? `N/A`}
        />
      )}

      <div className='surveys-meta'>
        {schoolInformation && (
          <div className='tabular-info tabular-info-multicolumn'>
            <div className='tabular-info-column'>
              {schoolInformation?.udiseId && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>UDISE Number</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.udiseId}
                  </span>
                </div>
              )}

              {schoolInformation?.schoolName && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>School</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.schoolName}
                  </span>
                </div>
              )}
            </div>

            <div className='tabular-info-column'>
              {schoolInformation?.district && (
                <div className='tabular-info-line'>
                  <span className='tabular-info-heading'>District</span>
                  <span className='tabular-info-description'>
                    {schoolInformation?.district}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='surveys-content'>
        <DropDown
          label='Select Class'
          id='surveyStartClass'
          name='surveyStartClass'
          renderingOptions={classData}
          onChange={handleFieldUpdate}
          showConvertedSelection={true}
          placeholder={`Select the Class you are observing`}
        />

        {location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
          location?.state?.surveyType !== SurveyTypes.readinessEndline &&
          location?.state?.surveyType !== SurveyTypes.term1Exam &&
          location?.state?.surveyType !== SurveyTypes.term2Exam &&
          location?.state?.surveyType !== SurveyTypes.term3Exam &&
          location?.state?.surveyType !== SurveyTypes.term4Exam &&
          location?.state?.surveyType !== SurveyTypes.halfye &&
          location?.state?.surveyType !== SurveyTypes.finalExam && (
            <DropDown
              label='Select Section'
              id='surveyStartSection'
              name='surveyStartSection'
              onChange={handleFieldUpdate}
              renderingOptions={sectionMaster}
              placeholder={`Not sure? Choose Not Available`}
            />
          )}

        {location?.state?.surveyType !== SurveyTypes.readinessBaseline &&
          location?.state?.surveyType !== SurveyTypes.readinessEndline &&
          location?.state?.surveyType !== SurveyTypes.term1Exam &&
          location?.state?.surveyType !== SurveyTypes.term2Exam &&
          location?.state?.surveyType !== SurveyTypes.term3Exam &&
          location?.state?.surveyType !== SurveyTypes.term4Exam &&
          location?.state?.surveyType !== SurveyTypes.halfye &&
          location?.state?.surveyType !== SurveyTypes.finalExam && (
            <DropDown
              label='Select Teacher'
              id='surveyStartTeacher'
              name='surveyStartTeacher'
              onChange={handleFieldUpdate}
              placeholder={`'Not on list? Add in School Profile`}
              showConvertedSelection={true}
              renderingOptions={parseTeachersForDropdown(
                schoolInformation?.teachers
              )}
            />
          )}

        {subjectsData &&
          Array.isArray(subjectsData) &&
          subjectsData.length !== 0 && (
            <div className='surveys-begin-subsection'>
              <h3 className='subsection-heading'>Choose Subjects</h3>

              {subjectsData.map(
                (currentSubjectObject, currentSubjectObjectIndex) => {
                  return (
                    <button
                      type='button'
                      key={currentSubjectObjectIndex}
                      className={getRenderingSubjectButtonClasses(
                        currentSubjectObject.value
                      )}
                      onClick={() =>
                        handleToggleSelectedSubject(currentSubjectObject)
                      }
                    >
                      {currentSubjectObject.label}
                    </button>
                  );
                }
              )}
            </div>
          )}

        <button
          type='button'
          onClick={() => handleProceed()}
          disabled={startSurveyButtonDisabled}
          className='primary-cta primary-cta-fullwidth primary-cta-centered'
        >
          Start Survey
        </button>
      </div>

      {errorDialogVisible && (
        <FeatureModal
          actionText='Close'
          buttonDisabled={false}
          handleClose={handleErrorDialogToggle}
          handleSubmit={handleErrorDialogToggle}
          headerChildren={
            <div className='surveys-begin-modalheader'>
              <h4 className='surveys-begin-modaltitle'>
                Survey already taken!
              </h4>
            </div>
          }
          contentChildren={
            <>
              <p>
                Please change the combination of class, subject, teacher (if
                available) and try again.
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

export default SurveysBegin;
