import React from 'react';

const ProgressQuestion = (props) => {
  return (
    <div className='progress-question'>
      <div className='progress-question-content'>
        <p className='progress-question-text'>{`Question ${props.questionsData.currentQuestion}/${props.questionsData.totalQuestions}`}</p>
      </div>

      <div className='progress-question-track'>
        <div
          className='progress-question-bar'
          style={{ width: props.filledProgress }}
        />
      </div>
    </div>
  );
};

export default ProgressQuestion;
