import React from 'react';

const UploadArea = (props) => {
  const { photoOne, photoTwo, handleUploadPhoto, handleRemovePhoto } = props;

  return (
    <div className='upload-area'>
      <div className='upload-area-container'>
        {photoOne && (
          <button
            type='button'
            className='upload-area-remove'
            onClick={() =>
              handleRemovePhoto('photoOne', photoOne.fileIdentifier)
            }
          >
            <span className='upload-area-removeicon' />
          </button>
        )}

        {!photoOne ? (
          <label htmlFor='uploadFileOne' className='upload-area-label'>
            <input
              type='file'
              id='uploadFileOne'
              name='uploadFileOne'
              accept='.JPG,.JPEG,.PNG'
              className='upload-area-field'
              onChange={(event) =>
                handleUploadPhoto('photoOne', event.target.files[0])
              }
            />

            <span className='upload-area-fieldicon' />
          </label>
        ) : (
          <img
            width='100%'
            height='100%'
            alt='Uploaded Photo'
            className='upload-area-photo'
            src={photoOne ? photoOne.fileUrl : '#'}
          />
        )}
      </div>

      <div className='upload-area-container'>
        {photoTwo && (
          <button
            type='button'
            className='upload-area-remove'
            onClick={() =>
              handleRemovePhoto('photoTwo', photoTwo.fileIdentifier)
            }
          >
            <span className='upload-area-removeicon' />
          </button>
        )}

        {!photoTwo ? (
          <label htmlFor='uploadFileTwo' className='upload-area-label'>
            <input
              type='file'
              id='uploadFileTwo'
              name='uploadFileTwo'
              accept='.JPG,.JPEG,.PNG'
              className='upload-area-field'
              onChange={(event) =>
                handleUploadPhoto('photoTwo', event.target.files[0])
              }
            />

            <span className='upload-area-fieldicon' />
          </label>
        ) : (
          <img
            width='100%'
            height='100%'
            alt='Uploaded Photo'
            className='upload-area-photo'
            src={photoTwo ? photoTwo.fileUrl : '#'}
          />
        )}
      </div>
    </div>
  );
};

export default UploadArea;
