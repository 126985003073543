import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import AuthLogin from 'containers/Auth/AuthLogin';
import AuthChoice from 'containers/Auth/AuthChoice';
import Dashboard from 'containers/AppCore/Dashboard';
import SplashLanding from 'containers/SplashLanding';
import SurveysBegin from 'containers/AppCore/SurveysBegin';
import SurveysHistory from 'containers/AppCore/SurveysHistory';
import SurveysPerformer from 'containers/AppCore/SurveysPerformer';
import ProfileStaticView from 'containers/AppCore/ProfileStaticView';

// REMOVED ON REQUEST FROM WISE OWL LEARNING
// import AuthRegisterUdise from 'containers/AuthRegisterUdise';
// import AuthRegisterAccount from 'containers/AuthRegisterAccount';
// DO NOT DELETE THESE IMPORT BLOCKS

import SurveysReview from 'containers/AppCore/SurveysReview';
import SurveysSuccess from 'containers/AppCore/SurveysSuccess';
import SurveysMetaInfo from 'containers/AppCore/SurveysMetaInfo';
import AuthRegisterProfile from 'containers/Auth/AuthRegisterProfile';
import AuthRegisterTeachers from 'containers/Auth/AuthRegisterTeachers';
import ProfileTeachersEdit from 'containers/AppCore/ProfileTeachersEdit';
import SurveysMetaProofing from 'containers/AppCore/SurveysMetaProofing';

const Navigation = () => {
  return (
    <div className='responsive-container'>
      <BrowserRouter>
        <Routes>
          {/* Define Top Level Landing Page - Splash */}
          <Route
            exact
            index
            path={NavigationPaths.HOME}
            element={<SplashLanding />}
          />

          {/* Define Top Level Route - Auth */}
          <Route path={NavigationPaths.ONBOARDING} element={<Outlet />}>
            <Route index exact element={<AuthChoice />} />

            <Route
              path={NavigationPaths.ONBOARDINGREGISTER}
              element={<Outlet />}
            >
              <Route index exact element={<AuthRegisterProfile />} />
              {/* REMOVED ON REQUEST FROM WISE OWL LEARNING */}

              {/* <Route exact path={'udise'} element={<AuthRegisterUdise />} /> */}
              {/* <Route
                  exact
                  path={'account'}
                  element={<AuthRegisterAccount />}
                /> */}

              <Route
                exact
                path={NavigationPaths.ONBOARDINGTEACHERS}
                element={<AuthRegisterTeachers />}
              />

              {/* Catch-All Block */}
              <Route
                path={NavigationPaths.CATCHALL}
                element={
                  <Navigate replace to={`/${NavigationPaths.ONBOARDING}`} />
                }
              />
            </Route>

            <Route
              exact
              path={NavigationPaths.ONBOARDINGLOGIN}
              element={<AuthLogin />}
            />

            {/* Catch-All Block */}
            <Route
              path={NavigationPaths.CATCHALL}
              element={
                <Navigate replace to={`/${NavigationPaths.ONBOARDING}`} />
              }
            />
          </Route>

          {/* Define Top Level Route - App */}
          <Route path={NavigationPaths.APPCORE} element={<Outlet />}>
            <Route index exact element={<Dashboard />} />

            <Route path={NavigationPaths.APPCOREPROFILE} element={<Outlet />}>
              <Route index exact element={<ProfileStaticView />} />

              <Route
                exact
                path={NavigationPaths.APPCOREPROFILETEACHERS}
                element={<ProfileTeachersEdit />}
              />
            </Route>

            <Route path={NavigationPaths.SURVEY} element={<Outlet />}>
              <Route index exact element={<SurveysHistory />} />

              <Route
                exact
                path={NavigationPaths.SURVEYBEGIN}
                element={<SurveysBegin />}
              />

              <Route
                exact
                path={NavigationPaths.SURVEYPERFORMER}
                element={<SurveysPerformer />}
              />

              <Route
                exact
                element={<SurveysMetaInfo />}
                path={NavigationPaths.SURVEYMETA}
              />

              <Route
                exact
                element={<SurveysMetaProofing />}
                path={NavigationPaths.SURVEYPROOFING}
              />

              <Route
                exact
                element={<SurveysReview />}
                path={NavigationPaths.SURVEYREVIEW}
              />

              <Route
                exact
                element={<SurveysSuccess />}
                path={NavigationPaths.SURVEYSUCCESS}
              />

              {/* Catch-All Block */}
              <Route
                path={NavigationPaths.CATCHALL}
                element={
                  <Navigate replace to={`/${NavigationPaths.APPCORE}`} />
                }
              />
            </Route>
          </Route>

          {/* Define Top Level Catch-All Block - 404 */}
          <Route
            path={NavigationPaths.CATCHALL}
            element={<Navigate replace to={'/'} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Navigation;
