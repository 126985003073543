import React, { useEffect, useState } from 'react';

const DropDown = (props) => {
  // Handle Pre-Assignment of Data from Here
  const [fieldValue, setFieldValue] = useState('');
  const [dropdownExpanded, setDropdownExpanded] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleFieldBoundaryClick = (event) => {
    const targetClassName = event?.target?.className;

    if (targetClassName && targetClassName.split(' ').includes('form-field')) {
      document.getElementById(`dropdown-${props.id}`).focus();
    }
  };

  const handleDropdownVisibility = (event) => {
    const targetClassName =
      event?.target?.parentElement?.parentElement?.className;

    if (
      targetClassName &&
      targetClassName.split(' ').includes('dropdown-field-expanded')
    ) {
      setDropdownExpanded(false);
    } else {
      setDropdownExpanded(true);
    }
  };

  const handleDropdownSelection = (selectedOptionValue) => {
    if (!props.showConvertedSelection) {
      setFieldValue(selectedOptionValue);
    } else {
      props.renderingOptions.forEach((currentRenderingOption) => {
        if (currentRenderingOption.value === selectedOptionValue) {
          setFieldValue(currentRenderingOption.label);
        }
      });
    }

    setDropdownExpanded(false);

    // Call Handler Callback from Here
    props.onChange(props.id, selectedOptionValue);
  };

  const handleOutsideClick = (event) => {
    let progressiveTarget = event?.target;
    const clickedElement = document.getElementById(props.id);

    do {
      // Click is Inside
      if (progressiveTarget === clickedElement) {
        return;
      }

      progressiveTarget = progressiveTarget?.parentNode;
    } while (progressiveTarget);

    // Click is Outside
    setDropdownExpanded(false);
  };

  const getRenderingFieldStates = () => {
    let fieldClasses = ['form-field'];

    if (!!props.rightAction) {
      fieldClasses.push('form-field-actionable');
    }

    if (fieldValue && fieldValue !== '') {
      fieldClasses.push('form-field-dirty');
    }

    return fieldClasses.join(' ');
  };

  const handleFieldValueUpdate = (event) => {
    setFieldValue(event.target.value);
  };

  return (
    <div
      id={props.id}
      className={
        dropdownExpanded
          ? 'dropdown-field dropdown-field-expanded'
          : 'dropdown-field'
      }
    >
      <div
        onClick={handleFieldBoundaryClick}
        className={getRenderingFieldStates()}
      >
        <label className='form-field-label'>{props.label}</label>
        <input
          type='text'
          readOnly={true}
          name={props.name}
          value={fieldValue}
          id={`dropdown-${props.id}`}
          placeholder={props.placeholder}
          onChange={handleFieldValueUpdate}
          onFocus={handleDropdownVisibility}
          className='form-field-input dropdown-field-input'
        />
      </div>

      {props.renderingOptions.length !== 0 ? (
        <div className='dropdown-field-dropdown'>
          {props.renderingOptions.map(
            (currentRenderingOption, currentRenderingOptionIndex) => {
              return (
                <button
                  type='button'
                  key={currentRenderingOptionIndex}
                  className='dropdown-field-option'
                  onClick={() =>
                    handleDropdownSelection(currentRenderingOption?.value)
                  }
                >
                  {currentRenderingOption?.label}
                </button>
              );
            }
          )}
        </div>
      ) : (
        <div className='dropdown-field-dropdown'>
          <button
            type='button'
            className='dropdown-field-option'
            onClick={() => handleDropdownSelection(null)}
          >
            No Choices Available
          </button>
          ;
        </div>
      )}
    </div>
  );
};

DropDown.defaultProps = {
  renderingOptions: [],
  onChange: () => {},
  showConvertedSelection: false,
};

export default DropDown;
