import React from 'react';

import LoaderGif from 'assets/images/loader.gif';

const GlobalLoader = (props) => {
  return (
    <div className='global-loader'>
      <div className='global-loader-content'>
        <img src={LoaderGif} className='global-loader-image' alt='Loading...' />
      </div>
    </div>
  );
};

export default GlobalLoader;
